import { Modal, ModalBody } from "baseui/modal"
import { Button } from "baseui/button"
import { usePoints } from "../../providers/PointsProvider"
import Link from "next/link"

export function PointChargeNoticeModal({
  open,
  fee,
  onClose,
}: {
  open?: boolean
  fee?: number
  onClose?: () => void
}) {
  const { points, hasNegativePoints } = usePoints()
  return (
    <Modal
      isOpen={open !== undefined ? open : hasNegativePoints}
      closeable={false}
    >
      <ModalBody>
        <div className="flex flex-col items-center gap-y-5 px-4 py-8">
          <div className="text-2xl font-bold text-black">
            {fee === undefined ? "포인트를 충전해주세요" : "포인트가 부족해요"}
          </div>
          <div className="rounded-xl bg-gray-200 px-6 py-4 text-2xl font-bold text-black">
            잔액: {points.remainingPoints.toLocaleString()}원
          </div>
          <div className="my-2 text-center">
            {hasNegativePoints && (
              <p>
                입력한 물품의 사이즈가 다르거나 기타 이유로 포인트가 차감됐어요.
              </p>
            )}
            {fee !== undefined && fee !== 0 ? (
              <p>주문 비용은 {fee.toLocaleString()} 포인트에요.</p>
            ) : (
              <p>포인트를 충전해야 서비스를 이용할 수 있어요.</p>
            )}
          </div>
          <div className="flex w-full flex-col gap-x-4 gap-y-2 md:w-7/12">
            {fee !== undefined ? (
              <Button
                size="compact"
                className="rounded border border-solid border-gray-700 bg-transparent py-3 font-semibold text-black hover:bg-gray-100"
                onClick={() => onClose?.()}
              >
                다음에 할게요
              </Button>
            ) : null}
            <Link href="/points" passHref>
              <Button
                size="compact"
                className="rounded bg-blue-500 py-3 font-semibold hover:bg-blue-600"
              >
                {hasNegativePoints
                  ? "차감내역 확인하기"
                  : "포인트 충전하러 가기"}
              </Button>
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
