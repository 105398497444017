import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"

export function isSenderCustomer<T extends Order | Load>(order: T): boolean {
  // FROM_72, RETURNING 의 경우에는 sender 가 customer (end-user, 주문 접수하지 않은쪽) 이고, 그렇지 않은 경우에는 receiver 가 customer 이다.
  return (
    order.deliveryClass === "FROM_72" || order.deliveryClass === "RETURNING"
  )
}

// 코드를 쉽게 읽기 위한 편의 목적
export function isSenderClient<T extends Order | Load>(order: T): boolean {
  return !isSenderCustomer(order)
}
