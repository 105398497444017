import axios from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { TodayAPIClient } from "../common"

export class MQTTAuth extends TodayAPIClient {
  constructor(baseUrl: string, token?: string) {
    super(
      applyCaseMiddleware(
        axios.create({
          baseURL: baseUrl,
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        })
      )
    )
  }

  async getPreSignedURL(): Promise<string> {
    const { data } = await this.client.get(`/mqtt/v1/auth/signed-wss-url`)
    return data.url
  }
}
