import { Input, InputProps } from "baseui/input"
import { ReactNode } from "react"

type OrderInputProps = {
  label: string
  onValueChange?: (value: string) => void
  required?: boolean
  trailing?: ReactNode
  component?: ReactNode
}

export function OrderInput({
  label,
  onValueChange,
  required = false,
  trailing,
  component,
  ...props
}: OrderInputProps & InputProps) {
  return (
    <div>
      <div className="mb-2 text-sm">
        {label} {required && <span className="text-red-500">*</span>}
      </div>
      <div className="flex items-center gap-x-2">
        {component ?? (
          <Input
            onChange={(event) => onValueChange?.(event.currentTarget.value)}
            {...props}
          />
        )}
        {trailing}
      </div>
    </div>
  )
}
