type Value<
  T extends Record<string, any>,
  K extends keyof T = keyof T
> = NonNullable<T[K]> extends string | Array<string> | boolean
  ? string | [string]
  : never
type Mapper<
  T extends Record<string, any>,
  K extends keyof T = keyof T
> = NonNullable<T[K]> extends Array<infer E>
  ? (elem: E) => string
  : (value: NonNullable<T[K]>) => string

type KeyMapValue = string | [string] | [string, (v: any) => string]

type KeyMap<T extends Record<string, KeyMapValue>> = {
  [key in keyof T]: Value<T, key> | [string, Mapper<T, key>]
}

export function encodeFilter<Filter extends Record<string, any>>(
  filter: Filter,
  keyMap: KeyMap<Filter>
) {
  return Object.entries<KeyMapValue>(keyMap)
    .map<[keyof Filter, KeyMapValue]>(([k, v]) => [k as keyof Filter, v])
    .filter(([k, v]) => filter[k] !== undefined)
    .map<[string, string]>(([filterKey, data]) => {
      const key = typeof data === "string" ? data : data[0]
      const value = filter[filterKey]
      if (!Array.isArray(data)) {
        return [key, value]
      }
      if (data.length === 1) {
        if (typeof value === "boolean") {
          return [key, value ? "true" : "false"]
        }
        if (Array.isArray(value)) {
          return [key, value.join(",")]
        }
        return [key, value]
      }
      if (Array.isArray(value)) {
        return [key, value.map((elem: any) => data[1](elem)).join(",")]
      }
      return [key, data[1](value)]
    })
    .map(([k, v]) => `${k}=${v}`)
    .join(";")
}
