import { Client, ClientCondition } from "@today/api/taker"

export function getShippingTypes(client?: Client) {
  if (!client || !client.conditions) return []
  return [
    ...client.conditions
      .reduce<Map<ClientCondition["shippingType"], boolean>>(
        (acc, condition) => acc.set(condition.shippingType, true),
        new Map()
      )
      .keys(),
  ]
}
