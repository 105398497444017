import { Client, Order, Product, ShippingPlace } from "@today/api/taker"
import { Load } from "@today/api/tracker"
import {
  formatInvoiceNumber,
  getCustomer,
  getForwardingInvoiceNumber,
  getFullAddress,
  getHoldTimeOfHoldingDeliveryOrder,
  getOrderStateName,
} from "@today/lib/utils"
import dayjs from "dayjs"
import keyBy from "lodash/keyBy"
import { isLegacyClient } from "./legacy-clients"

type Elem = [string, (order: Order, product?: Product) => string]

function getColumns(
  client: Client,
  shippingPlaces: ShippingPlace[],
  heldLoads: Load[]
): Elem[] {
  const heldLoadsById = keyBy(heldLoads, (load) => load.id)
  const isLegacy = isLegacyClient(client)

  return [
    [
      "송장번호",
      (order) => {
        if (order.deliveryClass === "FORWARDING_24") {
          const forwardingInvoiceNumber = getForwardingInvoiceNumber(order)
          return forwardingInvoiceNumber
            ? formatInvoiceNumber(forwardingInvoiceNumber)
            : "송장번호 발급 전"
        }

        return formatInvoiceNumber(order.invoiceNumber)
      },
    ],
    [
      "송장번호 발급 택배사",
      (order) =>
        order.deliveryClass === "FORWARDING_24"
          ? order.forwardingInfo?.deliveryOrganizationName ?? "CJ" // deliveryClass가 FORWARDING_24이지만 forwardingInfo가 없는 경우는 패스트박스를 통한 CJ 위탁임
          : "투데이",
    ],
    ["배송 분류", (order) => (order.returningInfo ? "반품배송" : "일반배송")],
    ["주문번호", (order) => order.clientOrderId],
    [
      "상품 ID",
      (order) =>
        order.products
          .filter((p) => p.clientProductId)
          .map((p) => p.clientProductId)
          .join(","),
    ],
    ...((isLegacy
      ? [
          [
            "출고지",
            (order) =>
              shippingPlaces.find(
                (sp) =>
                  sp.id ===
                  (order.returningInfo
                    ? order.receiver.shippingPlaceId
                    : order.sender.shippingPlaceId)
              )?.name ?? "",
          ],
        ]
      : [
          [
            "보낸이 출고지",
            (order) =>
              shippingPlaces.find(
                (sp) => sp.id === order.sender.shippingPlaceId
              )?.name ?? "",
          ],
          [
            "받는이 출고지",
            (order) =>
              shippingPlaces.find(
                (sp) => sp.id === order.receiver.shippingPlaceId
              )?.name ?? "",
          ],
        ]) as Elem[]),
    [
      "물품명",
      (order, product) =>
        product
          ? product.name
          : order.products.map((product) => product.name).join(", "),
    ],
    [
      "물품 코드",
      (order, product) =>
        product
          ? product.clientProductId ?? ""
          : order.products
              .map((product) => product.clientProductId)
              .filter((id) => !!id)
              .join(", "),
    ],
    [
      "고객 이름",
      (order) => {
        return getCustomer(order).name
      },
    ],
    [
      "고객 전화번호",
      (order) => {
        return getCustomer(order).phone
      },
    ],
    [
      "고객 우편번호",
      (order) => {
        return getCustomer(order).address.postalCode
      },
    ],
    [
      "고객 주소",
      (order) => {
        return getFullAddress(getCustomer(order))
      },
    ],
    [
      "원송장번호",
      (order) =>
        order.returningInfo?.invoiceNumber.match(/.{4}/g)?.join("-") ?? "",
    ],
    [
      "배송 상태",
      (order) => getOrderStateName(order.state, !order.sender.shippingPlaceId),
    ],
    [
      "접수 시각",
      (order) => dayjs(order.orderTime).format("YYYY-MM-DD HH:mm:ss"),
    ],
    [
      "출고 시각",
      (order) =>
        order.takeOutTime
          ? dayjs(order.takeOutTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
    ],
    [
      "픽업 시각",
      (order) =>
        order.pickUpTime
          ? dayjs(order.pickUpTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
    ],
    [
      "배송완료 시각",
      (order) =>
        order.state === "DELIVERED" && order.resolveTime
          ? dayjs(order.resolveTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
    ],
    [
      "배송중단 시각",
      (order) =>
        order.state === "QUIT" && order.resolveTime
          ? dayjs(order.resolveTime).format("YYYY-MM-DD HH:mm:ss")
          : "",
    ],
    [
      "배송보류 시각",
      (order) => getHoldTimeOfHoldingDeliveryOrder(order, heldLoadsById) ?? "",
    ],
  ]
}

export function ordersToPod(
  client: Client,
  orders: Order[],
  shippingPlaces: ShippingPlace[],
  heldLoads: Load[],
  linePerProduct?: boolean
): string[][] {
  const pod: string[][] = []
  const columns = getColumns(client, shippingPlaces, heldLoads)
  pod.push(columns.map(([label, _]) => label))
  for (const order of orders) {
    if (linePerProduct) {
      pod.push(
        ...order.products.map((product) =>
          columns.map(([_, mapOrderToValue]) => mapOrderToValue(order, product))
        )
      )
    } else {
      pod.push(columns.map(([_, mapOrderToValue]) => mapOrderToValue(order)))
    }
  }
  return pod
}
