import { Address as TakerAddress } from "@today/api/taker"
import { Address as TrackerAddress } from "@today/api/tracker"

export function getFullAddress(user: {
  address: TakerAddress | TrackerAddress
  rawAddress: string
}): string {
  const { address, rawAddress } = user
  if (address.streetBaseAddress || address.streetDetailAddress) {
    return address.streetBaseAddress + " " + address.streetDetailAddress
  }
  if (address.regionBaseAddress || address.regionDetailAddress) {
    return address.regionBaseAddress + " " + address.regionDetailAddress
  }
  return rawAddress
}
