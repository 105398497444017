export function LoadingOverlay() {
  return (
    <div
      className="flex h-screen w-full items-center justify-center gap-2 bg-gray-100"
      style={{ height: "100dvh" }}
    >
      <div className="h-5 w-5 animate-pulse rounded-full bg-gray-600"></div>
      <div className="h-5 w-5 animate-pulse rounded-full bg-gray-600"></div>
      <div className="h-5 w-5 animate-pulse rounded-full bg-gray-600"></div>
    </div>
  )
}
