import { APIProvider } from "@today/api"
import { AuthenticationProvider, AuthInitialProps, withAuth } from "@today/auth"
import axios from "axios"
import { BaseProvider, LightTheme, LocaleProvider } from "baseui"
import { AppContext, AppProps } from "next/app"
import Head from "next/head"
import { RecoilRoot } from "recoil"
import { Provider as StyletronProvider } from "styletron-react"
import { SWRConfig } from "swr"
import { NavBar } from "../components"
import {
  NavBar as KioskNavBar,
  Container as KioskContainer,
} from "../components/kiosk"
import { RequiredNoticeModal } from "../components/modal/RequiredNoticeModal"
import { authConfig, INVOICE_BASE_URL, TAKER_BASE_URL } from "../config"
import locale from "../locale"
import "../styles/globals.css"
import "../styles/kiosk.css"
import { styletron } from "../styletron"
import { LoadingOverlay } from "../components/common/LoadingOverlay"
import { useRouter } from "next/router"
import { useEffect } from "react"
import * as gtag from "../lib/gtag"
import Script from "next/script"
import { PointsProvider } from "../providers/PointsProvider"
import { ToasterContainer } from "baseui/toast"

function App({
  Component,
  pageProps,
  authInitialProp,
}: AppProps & AuthInitialProps) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  // 키오스크 페이지
  if (router.route.startsWith("/kiosk")) {
    return (
      <>
        <Head>
          <title>Today 파트너</title>
          <meta name="google" content="notranslate" />
        </Head>
        <RecoilRoot>
          <APIProvider
            takerBaseUrl={TAKER_BASE_URL}
            invoiceBaseUrl={INVOICE_BASE_URL}
          >
            <StyletronProvider value={styletron}>
              <BaseProvider theme={LightTheme}>
                <LocaleProvider locale={locale}>
                  <KioskContainer>
                    <KioskNavBar />
                    <div
                      className="flex-1 overflow-hidden"
                      style={{
                        wordBreak: "keep-all",
                      }}
                    >
                      <Component {...pageProps} />
                    </div>
                  </KioskContainer>
                  <RequiredNoticeModal />
                </LocaleProvider>
              </BaseProvider>
            </StyletronProvider>
          </APIProvider>
        </RecoilRoot>
      </>
    )
  }
  // 파트너 페이지
  return (
    <>
      <Head>
        <title>Today 파트너</title>
        <meta name="google" content="notranslate" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.trackingId}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.trackingId}`}
      />
      <RecoilRoot>
        <AuthenticationProvider
          {...authConfig}
          authInitialProp={authInitialProp}
          allowAnonymousPaths={{
            "/incheon": "exact",
            "/incheon/reset-password": "exact",
          }}
          loadingOverlay={<LoadingOverlay />}
        >
          <APIProvider
            takerBaseUrl={TAKER_BASE_URL}
            invoiceBaseUrl={INVOICE_BASE_URL}
          >
            <SWRConfig
              value={{
                fetcher: (url) => axios.get(url).then((res) => res.data),
              }}
            >
              <StyletronProvider value={styletron}>
                <BaseProvider theme={LightTheme}>
                  <LocaleProvider locale={locale}>
                    <ToasterContainer autoHideDuration={3000}>
                      <PointsProvider>
                        <div className="flex h-screen flex-col">
                          <NavBar />
                          <div
                            className="flex-1 overflow-hidden"
                            style={{
                              wordBreak: "keep-all",
                            }}
                          >
                            <Component {...pageProps} />
                          </div>
                        </div>
                        <RequiredNoticeModal />
                      </PointsProvider>
                    </ToasterContainer>
                  </LocaleProvider>
                </BaseProvider>
              </StyletronProvider>
            </SWRConfig>
          </APIProvider>
        </AuthenticationProvider>
      </RecoilRoot>
    </>
  )
}

App.getInitialProps = withAuth(async (context: AppContext) => ({}))

export default App
