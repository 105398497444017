import { Load, formatQuitReason, HoldOrQuitType } from "@today/api/tracker"
import { Client, Order } from "@today/api/taker"
import { useUserInfo } from "@today/auth"
import {
  formatDateTime,
  formatHoldDeliveryType,
  formatHoldPickUpType,
  formatInvoiceNumber,
  getCustomer,
  getDeliveryClassName,
  getForwardingInvoiceNumber,
  getFullAddress,
  getOrderStateName,
  parseDeveloperPayload,
} from "@today/lib"
import { Button, KIND } from "baseui/button"
import { Drawer, SIZE } from "baseui/drawer"
import { StyledLink } from "baseui/link"
import { Spinner } from "baseui/spinner"
import { toaster } from "baseui/toast"
import Link from "next/link"
import { useRouter } from "next/router"
import { ReactNode, useCallback, useState } from "react"
import { FaPlus, FaReply } from "react-icons/fa"
import { FiTruck } from "react-icons/fi"
import { ImCross } from "react-icons/im"
import useSWR from "swr"
import { MY_BASE_URL } from "../../config"
import { useSWRWithAuth, useTakerAuth } from "@today/api"
import { CancelModal } from "./CancelModal"

type OrderDetailDrawerProps = {
  orderId?: string
  onClose(): void
}

export function OrderDetailDrawer({
  orderId,
  onClose,
}: OrderDetailDrawerProps) {
  const { clientId } = useUserInfo()
  const router = useRouter()
  const taker = useTakerAuth()

  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { data: order } = useSWRWithAuth<Order>(
    clientId && orderId && `/api/clients/${clientId}/orders/${orderId}`
  )
  const { data: load } = useSWRWithAuth<Load>(
    orderId && `/api/loads/${orderId}`
  )
  const isReturning = order?.deliveryClass === "RETURNING"
  const isCollectFromCustomer = !order?.sender.shippingPlaceId

  const [isCancelModalVisible, setCancelModalVisible] = useState(false)

  const { data: holdDeliveryType } = useSWR<HoldOrQuitType>(
    load?.holdDeliveryInfo?.extraPayload?.holdOrQuitTypeId &&
      `/api/hold-or-quit-types/${load.holdDeliveryInfo.extraPayload.holdOrQuitTypeId}`
  )
  const { data: holdPickUpType } = useSWR<HoldOrQuitType>(
    load?.holdPickUpInfo?.extraPayload?.holdOrQuitTypeId &&
      `/api/hold-or-quit-types/${load.holdPickUpInfo.extraPayload.holdOrQuitTypeId}`
  )

  const getFields = useCallback((): {
    name: string
    value: ReactNode
  }[] => {
    if (!order) {
      return []
    }
    const customer = load ? getCustomer(load) : undefined
    const senderAddress = getFullAddress(order.sender)
    const receiverAddress = getFullAddress(order.receiver)
    const developerPayload = parseDeveloperPayload(order.developerPayload)
    const consumerReturningInfo = developerPayload["@today"]?.returning_info
    const forwardingInvoiceNumber = getForwardingInvoiceNumber(order)

    let invoiceNumber = order.invoiceNumber
    if (load?.deliveryClass === "FORWARDING_24") {
      invoiceNumber = forwardingInvoiceNumber
        ? formatInvoiceNumber(forwardingInvoiceNumber)
        : "송장번호 발급 전"
    } else if (load?.deliveryClass === "RETURNING" && client?.role === "SME") {
      invoiceNumber = formatInvoiceNumber(forwardingInvoiceNumber)
    }

    return [
      {
        name: "송장번호",
        value: invoiceNumber,
      },
      {
        name: "배송 분류",
        value: getDeliveryClassName(order.deliveryClass),
      },
      ...(order.returningInfo
        ? [
            {
              name: "원송장번호",
              value: formatInvoiceNumber(order.returningInfo.invoiceNumber),
            },
          ]
        : []),
      {
        name: "상태",
        value: getOrderStateName(
          order.state,
          !order.sender.shippingPlaceId,
          load?.state,
          client?.role
        ),
      },
      {
        name: "접수 시각",
        value: formatDateTime(order.orderTime),
      },
      {
        name: "픽업 시각",
        value: formatDateTime(order.pickUpTime),
      },
      {
        name: "배송 완료 시각",
        value: formatDateTime(order.resolveTime),
      },
      {
        name: "보낸이",
        value: `${order.sender.name} (${order.sender.phone})`,
      },
      {
        name: "보낸이 주소",
        value: (
          <StyledLink
            href={`https://map.naver.com/v5/search/${senderAddress}`}
            target="_blank"
          >
            {senderAddress}
          </StyledLink>
        ),
      },
      {
        name: "보낸이 우편번호",
        value: order.sender.address.postalCode,
      },
      {
        name: "받는이",
        value: `${order.receiver.name} (${order.receiver.phone})`,
      },
      {
        name: "받는이 주소",
        value: (
          <StyledLink
            href={`https://map.naver.com/v5/search/${receiverAddress}`}
            target="_blank"
          >
            {receiverAddress}
          </StyledLink>
        ),
      },
      {
        name: "받는이 우편번호",
        value: order.receiver.address.postalCode,
      },
      {
        name: isCollectFromCustomer ? "수거 방법" : "배송지 출입 방법",
        value: customer?.accessMethod,
      },
      {
        name: isCollectFromCustomer ? "수거 요청사항" : "배송 요청사항",
        value: customer?.preference,
      },
      {
        name: "물품명",
        value: order.products.map((product) => product.name).join("\n"),
      },
      ...(order.physicalAttributes
        ? [
            {
              name: "물품 속성",
              value: `${order.physicalAttributes.size} / (${
                order.physicalAttributes.width
              }x${order.physicalAttributes.height}x${
                order.physicalAttributes.depth
              }) ${order.physicalAttributes.fresh ? "/ 신선" : ""}`,
            },
          ]
        : []),
      ...(order.clientShippingId
        ? [
            {
              name: "고객사 내부 배송 ID",
              value: order.clientShippingId,
            },
          ]
        : []),
      ...(order.clientOrderId
        ? [
            {
              name: "고객사 내부 주문번호",
              value: order.clientOrderId,
            },
          ]
        : []),
      ...(load?.holdDeliveryInfo
        ? [
            {
              name: "배송 보류 시각",
              value: formatDateTime(load.holdDeliveryInfo.holdTime),
            },
            {
              name: "배송 보류 사유",
              value: [
                formatHoldDeliveryType(load.holdDeliveryInfo.type),
                load.holdDeliveryInfo.specialNote,
              ].join("\n"),
            },
          ]
        : []),
      ...(load?.holdPickUpInfo
        ? [
            {
              name: "수거 보류 시각",
              value: formatDateTime(load.holdPickUpInfo.holdTime),
            },
            {
              name: "수거 보류 사유",
              value: [
                formatHoldPickUpType(load.holdPickUpInfo.type),
                load.holdPickUpInfo.specialNote,
              ].join("\n"),
            },
          ]
        : []),
      ...(load?.unholdDeliveryInfo
        ? [
            {
              name: "배송 보류 해제 시각",
              value: formatDateTime(load.unholdDeliveryInfo.unholdTime),
            },
            {
              name: "배송 보류 해제 횟수",
              value: load.unholdDeliveryInfo.unholdCount,
            },
            {
              name: "배송 보류 해제 추가 정보",
              value: JSON.stringify(load.unholdDeliveryInfo.extraPayload),
            },
          ]
        : []),
      ...(load?.unholdPickUpInfo
        ? [
            {
              name: "수거 보류 해제 시각",
              value: formatDateTime(load.unholdPickUpInfo.unholdTime),
            },
            {
              name: "수거 보류 해제 횟수",
              value: load.unholdPickUpInfo.unholdCount,
            },
            {
              name: "수거 보류 해제 추가 정보",
              value: JSON.stringify(load.unholdPickUpInfo.extraPayload),
            },
          ]
        : []),
      ...(load?.quitInfo
        ? [
            {
              name: "배송 중단 시각",
              value: formatDateTime(load.quitInfo.quitTime),
            },
            {
              name: "배송 중단 사유",
              value: [
                formatQuitReason(load.quitInfo.reason),
                load.quitInfo.specialNote,
              ].join("\n"),
            },
          ]
        : []),
      ...(consumerReturningInfo
        ? [
            {
              name: "고객 요청",
              value: `${consumerReturningInfo.requested_action} (${consumerReturningInfo.returning_reason})`,
            },
          ]
        : []),
    ]
  }, [order, load, isCollectFromCustomer])
  return (
    <>
      <Drawer isOpen={!!orderId} onClose={onClose} size={SIZE.auto}>
        <div className="flex w-[36rem] flex-col">
          <div className="mb-4 text-xl font-semibold">물품 정보</div>
          {order ? (
            <div className="flex w-full flex-col gap-y-2">
              {getFields().map(({ name, value }) => (
                <div className="flex whitespace-pre-line">
                  <div className="w-32 font-semibold">{name}</div>
                  <div className="flex-1">{value}</div>
                </div>
              ))}
            </div>
          ) : (
            <Spinner />
          )}
          {load?.deliveryInfo && (
            <>
              <div className="mb-4 mt-8 text-xl font-semibold">
                배송 완료 사진
              </div>
              {load.deliveryInfo.imageUrl.startsWith("https://") ? (
                <div className="flex aspect-square w-full items-center justify-center">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={load.deliveryInfo.imageUrl}
                    className="max-h-full max-w-full"
                    alt="배송 완료 사진"
                  />
                </div>
              ) : (
                // 강제 배송 완료 등으로 사진이 없을 수 있다.
                "사진 없음"
              )}
            </>
          )}
          {order &&
            order.state !== "CANCELED" &&
            order.state !== "RETURNED" && (
              <>
                <div className="mb-4 mt-8 text-xl font-semibold">액션</div>
                <div className="flex flex-col gap-2">
                  <Button
                    kind={KIND.secondary}
                    startEnhancer={<FiTruck />}
                    onClick={() => {
                      const forwardingInvoiceNumber =
                        getForwardingInvoiceNumber(order)
                      window.open(
                        load?.deliveryClass === "FORWARDING_24"
                          ? load.forwardingInfo?.deliveryOrganizationName ===
                            "HANJIN"
                            ? `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&wblnumText2=${forwardingInvoiceNumber}&schLang=KR`
                            : `https://trace.cjlogistics.com/web/detail.jsp?slipno=${forwardingInvoiceNumber}`
                          : `${MY_BASE_URL}/track/${order.invoiceNumber}`,
                        "_ blank"
                      )
                    }}
                  >
                    배송조회
                  </Button>
                  <Link
                    href={`/orders/new?duplicate=${order.invoiceNumber}`}
                    passHref
                  >
                    <Button kind={KIND.secondary} startEnhancer={<FaPlus />}>
                      추가 송장 발행
                    </Button>
                  </Link>
                  {order.state === "TAKING_OUT" && (
                    <Button
                      kind={KIND.secondary}
                      startEnhancer={<ImCross />}
                      onClick={() => setCancelModalVisible(true)}
                      disabled={
                        order.state !== "TAKING_OUT" ||
                        (isReturning && client?.role == "SME")
                      }
                    >
                      배송요청 취소
                    </Button>
                  )}
                  {!isReturning &&
                    !order.deliveryClass.startsWith("INCHEON_") && (
                      <>
                        {
                          <Link
                            href={`/orders/new?returning=${order.invoiceNumber}`}
                            passHref
                          >
                            <Button startEnhancer={<FaReply />}>반품</Button>
                          </Link>
                        }
                      </>
                    )}
                </div>
              </>
            )}
        </div>
      </Drawer>
      <CancelModal
        isOpen={isCancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
        onCancel={async (reason) => {
          if (!clientId || !orderId) {
            return
          }
          await taker.cancelOrder(clientId, orderId, reason)
          setCancelModalVisible(false)
          toaster.info(<>배송요청이 취소되었습니다.</>, {})
          router.push("/orders")
        }}
      />
    </>
  )
}
