import { PrepaidPointsTransactionType } from "@today/api/taker"

export function getPrepaidPointsTransactionTypeName(
  type: PrepaidPointsTransactionType
) {
  switch (type) {
    case "POINTS_CHARGE":
      return "포인트 충전"
    case "POINTS_SPEND":
      return "배송비"
    case "POINTS_RESTORE":
      return "취소 복원"
    case "SHIPPING_FEE_ADJUSTMENT":
      return "추가 청구"
    case "ADMIN_ADJUSTMENT":
      return "관리자 변경"
    case "POINTS_REFUND":
      return "포인트 환불"
  }
}
