import {
  useTaker,
  useTracker,
  useTracer,
  useInvoice,
  useMqtt,
  useUrlRecord,
} from "./APIProvider"
import { useAuthentication } from "@today/auth"

export function useTakerAuth() {
  const { accessToken } = useAuthentication()
  return useTaker(accessToken)
}

export function useTrackerAuth() {
  const { accessToken } = useAuthentication()
  return useTracker(accessToken)
}

export function useTracerAuth() {
  const { accessToken } = useAuthentication()
  return useTracer(accessToken)
}

export function useInvoiceAuth() {
  const { accessToken } = useAuthentication()
  return useInvoice(accessToken)
}

export function useMqttAuth() {
  const { accessToken } = useAuthentication()
  return useMqtt(accessToken)
}

export function useUrlRecordAuth() {
  const { accessToken } = useAuthentication()
  return useUrlRecord(accessToken)
}
