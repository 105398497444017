import { Client } from "@today/api/taker"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { StyledLink } from "baseui/link"

type CutOffTimerProps = {
  client: Client
}

export function CutOffTimer({ client }: CutOffTimerProps) {
  const cutOffTime = useMemo(() => {
    if (!client.cutOffTime) return
    try {
      const [hh, mm, ss] = client.cutOffTime
        .split(":")
        .map((e) => Number.parseInt(e))
      return dayjs()
        .startOf("day")
        .add(hh, "hours")
        .add(mm, "minutes")
        .add(ss, "seconds")
    } catch {
      return
    }
  }, [client.cutOffTime])
  const isWeekday = useMemo(() => {
    const dayOfWeek = dayjs().format("d")
    return dayOfWeek !== "0" && dayOfWeek !== "6"
  }, [])
  const [remainingTime, setRemainingTime] = useState<string>()
  useEffect(() => {
    if (!cutOffTime || !isWeekday) {
      setRemainingTime(undefined)
      return
    }
    const ti = setInterval(() => {
      const now = dayjs()
      if (cutOffTime.isBefore(now)) {
        setRemainingTime(undefined)
        clearInterval(ti)
        return
      }
      const remainingMinutes = cutOffTime.diff(now, "minutes")
      if (remainingMinutes < 1) {
        setRemainingTime(`${cutOffTime.diff(now, "seconds")}초 전`)
      } else if (remainingMinutes < 60) {
        setRemainingTime(`${remainingMinutes}분 전`)
      } else {
        setRemainingTime(
          `${Math.floor(remainingMinutes / 60)}시간 ${
            remainingMinutes % 60
          }분 전`
        )
      }
    }, 100)
    return () => {
      clearInterval(ti)
    }
  }, [cutOffTime, isWeekday])
  if (!cutOffTime) {
    return <></>
  }
  return (
    <div className="w-full bg-gray-100 p-3 text-center text-2xl font-bold">
      픽업기사 배정 마감: 영업일 {cutOffTime.hour() < 12 ? "오전" : "오후"}{" "}
      {cutOffTime.hour() % 12}시
      {remainingTime && (
        <>
          {" "}
          <span className="text-red-500">({remainingTime})</span>
        </>
      )}{" "}
      <small className="ml-2">
        <a
          href="https://manual.amazing.today/bf66f513-952f-4282-8358-da846ecf9da7#64ac113c29204719a5caf94f532f29dd"
          target="_blank"
          rel="noreferrer"
        >
          <StyledLink>픽업정책 알아보기</StyledLink>
        </a>
      </small>
    </div>
  )
}
