const DEFAULT_PAGE_SIZE = 100

export type PaginationRequest<T = {}> = T & {
  pageToken?: string
  pageSize?: number
}

export type PaginationResponse<ResKey extends string, Res> = {
  [k in ResKey]: Res[]
} & { nextPageToken: string; totalCount: number }

export async function fetchAllPages<ResKey extends string, Res>(
  k: ResKey,
  q: { [key: string]: string },
  fetch: (
    q: {
      [key: string]: string
    },
    pageCount: number
  ) => Promise<PaginationResponse<ResKey, Res>>,
  useCamel?: boolean,
  maxPageCount?: number,
  updateProgress?: (
    current?: number | ((prev: number) => number),
    total?: number | ((prev: number) => number)
  ) => void
): Promise<Res[]> {
  let res: Res[] = []
  let firstFetch = true
  let nextPageToken = ""
  let pageCount = 0
  updateProgress?.(0, 1)
  while (firstFetch || nextPageToken) {
    if (maxPageCount !== undefined && ++pageCount > maxPageCount) {
      break
    }
    const data = await fetch(
      {
        ...q,
        ...(useCamel
          ? {
              pageToken: nextPageToken,
              pageSize: q.page_size ?? DEFAULT_PAGE_SIZE,
            }
          : {
              page_token: nextPageToken,
              page_size: q.page_size ?? DEFAULT_PAGE_SIZE,
            }),
      },
      pageCount
    )

    // Apply progress
    if (!updateProgress) {
      // Skip
    } else if (data.totalCount) {
      try {
        updateProgress(
          (prev) => prev + parseInt(q.pageSize ?? DEFAULT_PAGE_SIZE),
          data.totalCount
        )
      } catch {}
    } else if (firstFetch && data.nextPageToken) {
      updateProgress(1, 5)
    } else {
      updateProgress(
        (prev) => prev + 1,
        (prev) => prev + 1
      )
    }

    // Advance data
    res = [...res, ...(data[k] ?? [])]
    firstFetch = false
    nextPageToken = data.nextPageToken
  }
  updateProgress?.(1, 1)
  return res
}

export function withPagination(params?: PaginationRequest) {
  const p = new URLSearchParams()
  if (!params) return p
  if (params.pageSize) p.append("page_size", `${params.pageSize}`)
  if (params.pageToken) p.append("page_token", `${params.pageToken}`)
  return p
}
