import { useRouter } from "next/router"
import { PropsWithChildren, useEffect } from "react"
import { Flip, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import { KioskUserInfo } from "./KioskUserInfo"

export function Container({ children }: PropsWithChildren<{}>) {
  const router = useRouter()

  useEffect(() => {
    const stationId = (router.query.stationId as string) || ""
    const stationName = (router.query.stationName as string) || ""
    if (stationId) {
      localStorage.setItem("stationId", stationId)
    }
    if (stationName) {
      localStorage.setItem("stationName", stationName)
      document.title = `${stationName} 키오스크`
    }
  }, [router.query])

  return (
    <KioskUserInfo>
      <main
        className="flex h-screen flex-col p-20"
        draggable="false"
        style={{ touchAction: "none" }}
      >
        {children}
      </main>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme={"dark"}
        transition={Flip}
        closeButton={false}
      />
    </KioskUserInfo>
  )
}
