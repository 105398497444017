import { useTakerAuth, useInvoiceAuth, useSWRWithAuth } from "@today/api"
import { Client, ListShippingPlacesResponse, Order } from "@today/api/taker"
import { RegionSet } from "@today/api/tracker"
import { useUserInfo } from "@today/auth"
import { parseDeveloperPayload, partition, sleepAsync } from "@today/lib"
import { Button, KIND } from "baseui/button"
import { DatePicker } from "baseui/datepicker"
import { Plus, Upload } from "baseui/icon"
import { Input } from "baseui/input"
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal"
import { Popover } from "baseui/popover"
import { ALIGN, Radio, RadioGroup } from "baseui/radio"
import { Spinner } from "baseui/spinner"
import { Tab, Tabs } from "baseui/tabs-motion"
import { toaster } from "baseui/toast"
import ko from "date-fns/locale/ko"
import dayjs from "dayjs"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { BsFillFileEarmarkSpreadsheetFill, BsPrinterFill } from "react-icons/bs"
import { FaCheck } from "react-icons/fa"
import { ImCross } from "react-icons/im"
import { useRecoilState } from "recoil"
import { CancelModal, OrderTable, OrderTableProps } from "../../components"
import { AsyncActionButton } from "../common/AsyncActionButton"
import { OrderDetailDrawer } from "./OrderDetailDrawer"
import { dateRangeState, usePrintedOrderIds } from "../../state"
import {
  CHUNK_SIZE,
  createInvoicePdf,
  getStationCode,
  getShippingTypes,
  isLegacyClient,
  ordersToPod,
  PrintType,
  useOrders,
} from "../../utils"
import getBinder from "../../utils/binder"
import { useLoads } from "../../utils/useLoads"
import { CutOffTimer } from "../common/CutOffTimer"
import { TabKey } from "./types"
import { ShippingTypeFilter, useShippingTypeFilter } from "./ShippingTypeFilter"
import {
  ShippingPlaceFilter,
  useShippingPlaceFilter,
} from "./ShippingPlaceFilter"
import { StationCodeFilter, useStationCodeFilter } from "./StationCodeFilter"
import { useCustomColumns } from "./useCustomColumns"

type PrintFilterOption = "ALL" | "PRINTED" | "NOT_PRINTED"

const totalProductCountColumns: OrderTableProps["columns"] = [
  "TOTAL_PRODUCT_COUNT",
]
const shippingFeeColumns: OrderTableProps["columns"] = ["SHIPPING_FEE"]

export function OrderListPage() {
  const { clientId, clientRoles } = useUserInfo()
  const isTruckCaller = useMemo(
    () => clientRoles.includes("order:truck-caller"),
    [clientRoles]
  )
  const taker = useTakerAuth()
  const invoice = useInvoiceAuth()
  const router = useRouter()

  const [dateRange, setDateRange] = useRecoilState(dateRangeState)
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([])
  const [isTakingOut, setTakingOut] = useState(false)
  const [cancelingOrderIds, setCancelingOrderIds] = useState<string[]>([])
  const clearSelectionRef = useRef<() => void>()
  const [fromDate, toDate] = dateRange.map((date) =>
    dayjs(date).format("YYYY-MM-DD")
  )
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { data: shippingPlacesData } =
    useSWRWithAuth<ListShippingPlacesResponse>(
      clientId && `/api/clients/${clientId}/shipping-places`
    )
  const shippingPlaces = useMemo(
    () =>
      (shippingPlacesData?.shippingPlaces ?? []).sort((s1, s2) =>
        s1.name.localeCompare(s2.name)
      ),
    [shippingPlacesData]
  )

  const { data: regionSets } = useSWRWithAuth<RegionSet[]>(`/api/region-sets`)
  const { orders, mutate, isLoading } = useOrders({
    clientId,
    fromDate,
    toDate,
  })
  const binder = useMemo(
    () => getBinder(client, clientRoles),
    [client, clientRoles]
  )
  const { loads: heldLoads } = useLoads([
    `client_id=${clientId}`,
    fromDate ? `order_date_from=${fromDate}` : "",
    toDate ? `order_date_to=${toDate}` : "",
    `state=HOLDING_DELIVERY`,
  ])
  const [createInvoiceOptModal, setCreateInvoiceOptModal] = useState(false)
  const [createInvoiceOpt, setCreateInvoiceOpt] =
    useState<PrintType>("CHUNK_DOWNLOAD")
  const [isCreatingInvoice, setCreatingInvoice] = useState(false)
  const [invoiceProgress, setInvoiceProgress] = useState(0)
  const [activeKey, setActiveKey] = useState<TabKey>("TAKING_OUT")

  // 물품 필터링

  const [printFilterOption, setPrintFilterOption] =
    useState<PrintFilterOption>("ALL")
  const { printedOrderIds, markAsPrinted } = usePrintedOrderIds()

  const printFilter = useCallback(
    (order: Order) => {
      switch (printFilterOption) {
        case "ALL":
          return true
        case "PRINTED":
          return printedOrderIds.has(order.orderId)
        case "NOT_PRINTED":
          return !printedOrderIds.has(order.orderId)
      }
    },
    [printFilterOption, printedOrderIds]
  )

  const supportedShippingTypes = useMemo(
    () => getShippingTypes(client),
    [client]
  )

  // XXX: STATION_TO_LM과 LM_TO_STATION 두 가지만 지원하는 화주에 한해 필터링 활성화
  const enableShippingTypeFilter =
    supportedShippingTypes.length === 2 &&
    supportedShippingTypes.includes("STATION_TO_LM") &&
    supportedShippingTypes.includes("LM_TO_STATION")

  const {
    option: shippingTypeFilterOption,
    setOption: setShippingTypeFilterOption,
  } = useShippingTypeFilter()

  const enableShippingPlaceFilter =
    shippingPlaces.length > 1 &&
    !supportedShippingTypes.every((t) => t === "LM_TO_LM")

  const {
    option: shippingPlaceFilterOption,
    setOption: setShippingPlaceFilterOption,
  } = useShippingPlaceFilter()

  const {
    option: stationCodeFilterOption,
    setOption: setStationCodeFilterOption,
  } = useStationCodeFilter()

  const shippingTypeFilter = useCallback(
    (order: Order) => {
      // TODO: OpenAPI 상에서 order에 shipping type을 내려주면 편할 것 같다.
      switch (shippingTypeFilterOption) {
        case "ALL":
          return true
        case "DELIVERY":
          if (order.deliveryClass === "RETURNING") {
            return !!order.receiver.shippingPlaceId
          }
          return !!order.sender.shippingPlaceId
        case "LM_PICK_UP":
          if (order.deliveryClass === "RETURNING") {
            return !order.receiver.shippingPlaceId
          }
          return !order.sender.shippingPlaceId
      }
    },
    [shippingTypeFilterOption]
  )

  const shippingPlaceFilter = useCallback(
    (order: Order) => {
      switch (shippingPlaceFilterOption) {
        case "ALL":
          return true
        default:
          return (
            (order.sender.shippingPlaceId || order.receiver.shippingPlaceId) ===
            shippingPlaceFilterOption
          )
      }
    },
    [shippingPlaceFilterOption]
  )

  const stationCodeFilter = useCallback(
    (order: Order) => {
      return (
        stationCodeFilterOption === "ALL" ||
        getStationCode(order, regionSets) === stationCodeFilterOption
      )
    },
    [stationCodeFilterOption, regionSets]
  )

  const queriedOrders = useMemo(
    () =>
      orders
        ?.filter(
          searchQuery
            ? (order) =>
                order.invoiceNumber.startsWith(searchQuery.replace(/-/g, "")) ||
                order.sender.name.includes(searchQuery) ||
                order.receiver.name.includes(searchQuery) ||
                order.clientShippingId?.includes(searchQuery) ||
                order.forwardingInfo?.invoiceNumber.includes(searchQuery) ||
                order.sender.phone.includes(searchQuery) ||
                order.receiver.phone.includes(searchQuery) ||
                order.clientOrderId.includes(searchQuery)
            : (order) => order
        )
        .filter(shippingPlaceFilter),
    [orders, searchQuery, shippingPlaceFilter]
  )

  // 물품 종류별 파티셔닝
  const [testOrders, nonTestOrders] = useMemo(
    () =>
      partition(
        queriedOrders,
        (order) =>
          parseDeveloperPayload(order.developerPayload)["@today"]?.test ?? false
      ),
    [queriedOrders]
  )
  const [regularOrders, returningOrders] = useMemo(
    () =>
      partition(nonTestOrders, (order) => order.deliveryClass !== "RETURNING"),
    [queriedOrders]
  )

  const [takingOutOrderIds, setTakingOutOrderIds] = useState<string[]>([])
  const takeOut = useCallback(
    async (orderIds: string[]) => {
      if (!clientId) return
      setTakingOut(true)
      await Promise.all(
        orderIds.map((orderId) => taker.takeOutOrder(clientId, orderId))
      )
      mutate()
      setTakingOut(false)
    },
    [clientId, taker, mutate]
  )

  // 테이블 컬럼 정의
  const shippingPlaceColumns: OrderTableProps["columns"] = useMemo(
    () =>
      isLegacyClient(client)
        ? ["SHIPPING_PLACE"]
        : supportedShippingTypes.length === 1 &&
          supportedShippingTypes.includes("LM_TO_LM")
        ? []
        : ["SENDER_SHIPPING_PLACE", "RECEIVER_SHIPPING_PLACE"],
    [client, supportedShippingTypes]
  )

  const baseOrderTabs: {
    title: string
    key: TabKey
    orders: OrderTableProps["orders"]
    columns: OrderTableProps["columns"]
  }[] = useMemo(
    () => [
      {
        title: "전체",
        key: "ALL",
        orders: queriedOrders
          ?.filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "DELIVERY_CLASS",
          "CLIENT_ORDER_ID",
          ...shippingPlaceColumns,
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          ...(isTruckCaller ? shippingFeeColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_POSTAL_CODE",
          "CUSTOMER_ADDRESS",
          "CUSTOMER_PREFERENCE",
          "ORIGINAL_INVOICE_NUMBER",
          "STATE",
          "ORDER_TIME",
          "TAKE_OUT_TIME",
          "PICK_UP_TIME",
          "DELIVERY_TIME",
          "QUIT_TIME",
          "HOLD_DELIVERY_TIME",
        ],
      },
      {
        title: client?.role === "SME" ? "픽업 요청 전" : "출고 확정 전",
        key: "TAKING_OUT",
        orders: regularOrders
          ?.filter((order) => order.state === "TAKING_OUT")
          .filter(printFilter)
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "HAS_PRINTED_INVOICE",
          "DETAIL_BUTTON",
          "CLIENT_ORDER_ID",
          ...shippingPlaceColumns,
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          ...(isTruckCaller ? shippingFeeColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_ADDRESS",
        ],
      },
      {
        title: client?.role === "SME" ? "픽업 요청됨" : "출고 확정됨",
        key: "PENDING_COLLECT",
        orders: regularOrders
          ?.filter((order) => order.state === "PENDING_COLLECT")
          .filter(printFilter)
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "HAS_PRINTED_INVOICE",
          "DETAIL_BUTTON",
          "CLIENT_ORDER_ID",
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          ...(isTruckCaller ? shippingFeeColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_ADDRESS",
        ],
      },
      {
        title: "배송 중",
        key: "IN_SHIPPING",
        orders: regularOrders
          ?.filter((order) => order.state === "IN_SHIPPING")
          .filter(printFilter)
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "CLIENT_ORDER_ID",
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          ...(isTruckCaller ? shippingFeeColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_ADDRESS",
          "STATE",
          "TAKE_OUT_TIME",
          "PICK_UP_TIME",
          "HOLD_DELIVERY_TIME",
        ],
      },
      {
        title: "배송 완료",
        key: "DELIVERED",
        orders: queriedOrders
          ?.filter((order) => order.state === "DELIVERED")
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "DELIVERY_CLASS",
          "CLIENT_ORDER_ID",
          ...shippingPlaceColumns,
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          ...(isTruckCaller || client?.role === "SME"
            ? shippingFeeColumns
            : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_POSTAL_CODE",
          "CUSTOMER_ADDRESS",
          "CUSTOMER_PREFERENCE",
          "ORIGINAL_INVOICE_NUMBER",
          "ORDER_TIME",
          "TAKE_OUT_TIME",
          "PICK_UP_TIME",
          "DELIVERY_TIME",
        ],
      },
      {
        title: "배송 중단",
        key: "QUIT",
        orders: queriedOrders
          ?.filter((order) => order.state === "QUIT")
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "DELIVERY_CLASS",
          "CLIENT_ORDER_ID",
          ...shippingPlaceColumns,
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_POSTAL_CODE",
          "CUSTOMER_ADDRESS",
          "CUSTOMER_PREFERENCE",
          "ORIGINAL_INVOICE_NUMBER",
          "ORDER_TIME",
          "TAKE_OUT_TIME",
          "PICK_UP_TIME",
          "QUIT_TIME",
        ],
      },
      {
        title: "취소/반송됨",
        key: "CANCELED_OR_RETURNED",
        orders: regularOrders
          ?.filter(
            (order) => order.state === "CANCELED" || order.state === "RETURNED"
          )
          .filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "CLIENT_ORDER_ID",
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_ADDRESS",
        ],
      },
      {
        title: "반품",
        key: "IN_RETURNING",
        orders: (returningOrders ?? undefined)
          ?.filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "ORIGINAL_INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "CLIENT_ORDER_ID",
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_ADDRESS",
          "STATE",
        ],
      },
      {
        title: "테스트 물품",
        key: "TEST",
        orders: (testOrders ?? undefined)
          ?.filter(shippingTypeFilter)
          .filter(stationCodeFilter),
        columns: [
          "INVOICE_NUMBER",
          "DETAIL_BUTTON",
          "DELIVERY_CLASS",
          "CLIENT_ORDER_ID",
          ...shippingPlaceColumns,
          "PRODUCT_NAME",
          ...(isTruckCaller ? totalProductCountColumns : []),
          "CUSTOMER_NAME",
          "CUSTOMER_PHONE",
          "CUSTOMER_POSTAL_CODE",
          "CUSTOMER_ADDRESS",
          "CUSTOMER_PREFERENCE",
          "ORIGINAL_INVOICE_NUMBER",
          "STATE",
          "ORDER_TIME",
          "TAKE_OUT_TIME",
          "PICK_UP_TIME",
          "DELIVERY_TIME",
          "QUIT_TIME",
        ],
      },
    ],
    [
      client,
      isTruckCaller,
      queriedOrders,
      regularOrders,
      testOrders,
      returningOrders,
      shippingPlaceColumns,
      shippingTypeFilter,
      stationCodeFilter,
    ]
  )

  // 각 탭에 화주별 커스텀 옵션 설정
  const { stationCodeOptions: stationCodeOptions, orderTabs } =
    useCustomColumns(client, baseOrderTabs)

  const onClickCreateInvoiceButton = async (print: PrintType) => {
    if (!clientId) return
    setInvoiceProgress(5)
    setCreatingInvoice(true)
    const orderIds = selectedOrders.map((order) => order.orderId)
    const fileName =
      selectedOrders.length === 1
        ? `${selectedOrders[0].clientOrderId}_${selectedOrders[0].receiver.name}`
        : undefined
    await createInvoicePdf(
      invoice,
      clientId,
      selectedOrders,
      (createdOrderIds: string[]) => {
        setInvoiceProgress(
          (p) =>
            5 + 95 * ((p - 5) / 95 + createdOrderIds.length / orderIds.length)
        )
        markAsPrinted(createdOrderIds)
      },
      fileName,
      print
    )
    markAsPrinted(orderIds)
    await sleepAsync(1000)
    setCreatingInvoice(false)
    if (activeKey === "TAKING_OUT") {
      setTakingOutOrderIds(orderIds)
    }
  }

  // 모바일 환경에서는 date picker 1달만 표시
  const [monthsShown, setMonthsShown] = useState(2)
  useEffect(() => {
    setMonthsShown(window.innerWidth < 1024 ? 1 : 2)
  }, [])

  const selectedProductsCount = useMemo(() => {
    return selectedOrders.reduce(
      (acc, { products }) =>
        acc + products.reduce((acc, { count }) => acc + count, 0),
      0
    )
  }, [selectedOrders])

  const selectedPrintCount = isTruckCaller
    ? selectedProductsCount
    : selectedOrders.length

  return (
    <div className="flex h-full flex-col">
      {client?.cutOffTime && client?.role === "SME" && (
        <CutOffTimer client={client} />
      )}
      <div className="flex flex-col gap-2 p-2 md:flex-row md:items-center">
        <div className="w-full md:w-64">
          <DatePicker
            range
            locale={ko}
            value={dateRange}
            onChange={({ date }) => {
              setDateRange(date as Date[])
            }}
            monthsShown={monthsShown}
            filterDate={(day) => dayjs(day).startOf("day").isBefore(dayjs())}
          />
        </div>
        <div className="w-96 max-w-full">
          <Input
            placeholder="현재 리스트 내에서 검색..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            endEnhancer={isLoading && <Spinner $size="small" />}
          />
        </div>
        {(activeKey === "TAKING_OUT" || activeKey === "PENDING_COLLECT") && (
          <div className="ml-2">
            <RadioGroup
              value={printFilterOption}
              onChange={(e) =>
                setPrintFilterOption(e.currentTarget.value as PrintFilterOption)
              }
              name="print"
              align={ALIGN.horizontal}
            >
              <Radio value="ALL">전체</Radio>
              <Radio value="PRINTED">송장 출력된 건만</Radio>
              <Radio value="NOT_PRINTED">송장 출력되지 않은 건만</Radio>
            </RadioGroup>
          </div>
        )}
        <div className="flex-1" />
        {selectedOrders.length ? (
          <Popover
            isOpen={!!selectedOrders.length}
            placement="leftTop"
            content={
              <div className="flex w-64 flex-col gap-y-2 rounded bg-white p-6">
                <div className="mb-2 text-xl font-semibold">
                  선택된 주문 {selectedOrders.length}건
                </div>
                <AsyncActionButton
                  kind="secondary"
                  disabled={selectedPrintCount > CHUNK_SIZE}
                  startEnhancer={<BsPrinterFill />}
                  onClick={() => {
                    return onClickCreateInvoiceButton("PRINT")
                  }}
                >
                  송장 바로 인쇄
                </AsyncActionButton>
                <Button
                  kind="secondary"
                  startEnhancer={<BsPrinterFill />}
                  onClick={() => {
                    setCreateInvoiceOptModal(true)
                  }}
                >
                  송장 다운로드
                </Button>
                {selectedOrders.every(
                  (order) =>
                    order.state === "DELIVERED" || order.state === "QUIT"
                ) ? (
                  <>
                    <AsyncActionButton
                      kind="secondary"
                      startEnhancer={<BsFillFileEarmarkSpreadsheetFill />}
                      onClick={async () => {
                        if (!client || !orders || !heldLoads) return
                        const { shippingPlaces } =
                          await taker.listShippingPlaces(client.clientId)
                        const workbook = new ExcelJS.Workbook()
                        const sheet = workbook.addWorksheet()
                        sheet.addRows(
                          ordersToPod(
                            client,
                            selectedOrders,
                            shippingPlaces,
                            heldLoads,
                            false
                          )
                        )
                        const buffer = await workbook.xlsx.writeBuffer()
                        saveAs(
                          new Blob([buffer]),
                          `POD-${dayjs().format("YYYY-MM-DD")}.xlsx`
                        )
                      }}
                    >
                      POD 다운로드
                    </AsyncActionButton>
                    <AsyncActionButton
                      kind="secondary"
                      startEnhancer={<BsFillFileEarmarkSpreadsheetFill />}
                      onClick={async () => {
                        if (!client || !orders || !heldLoads) return
                        const { shippingPlaces } =
                          await taker.listShippingPlaces(client.clientId)
                        const workbook = new ExcelJS.Workbook()
                        const sheet = workbook.addWorksheet()
                        sheet.addRows(
                          ordersToPod(
                            client,
                            selectedOrders,
                            shippingPlaces,
                            heldLoads,
                            true
                          )
                        )
                        const buffer = await workbook.xlsx.writeBuffer()
                        saveAs(
                          new Blob([buffer]),
                          `POD-${dayjs().format("YYYY-MM-DD")}.xlsx`
                        )
                      }}
                    >
                      POD (물품 기준)
                    </AsyncActionButton>
                  </>
                ) : (
                  <>
                    <AsyncActionButton
                      kind="secondary"
                      startEnhancer={<BsFillFileEarmarkSpreadsheetFill />}
                      onClick={async () => {
                        if (!client || !orders || !heldLoads) return
                        const workbook = new ExcelJS.Workbook()
                        const sheet = workbook.addWorksheet()
                        const { shippingPlaces } =
                          await taker.listShippingPlaces(client.clientId)
                        sheet.addRows(
                          binder.export(
                            client,
                            selectedOrders,
                            shippingPlaces,
                            heldLoads,
                            false
                          )
                        )
                        const buffer = await workbook.xlsx.writeBuffer()
                        saveAs(
                          new Blob([buffer]),
                          `orders-${dayjs().format("YYYY-MM-DD")}.xlsx`
                        )
                      }}
                    >
                      엑셀 다운로드
                    </AsyncActionButton>
                    <AsyncActionButton
                      kind="secondary"
                      startEnhancer={<BsFillFileEarmarkSpreadsheetFill />}
                      onClick={async () => {
                        if (!client || !heldLoads) return
                        const workbook = new ExcelJS.Workbook()
                        const sheet = workbook.addWorksheet()
                        const { shippingPlaces } =
                          await taker.listShippingPlaces(client.clientId)
                        sheet.addRows(
                          binder.export(
                            client,
                            selectedOrders,
                            shippingPlaces,
                            heldLoads,
                            true
                          )
                        )
                        const buffer = await workbook.xlsx.writeBuffer()
                        saveAs(
                          new Blob([buffer]),
                          `orders-${dayjs().format("YYYY-MM-DD")}.xlsx`
                        )
                      }}
                    >
                      엑셀 다운로드 (물품 기준)
                    </AsyncActionButton>
                  </>
                )}
                <AsyncActionButton
                  kind="secondary"
                  startEnhancer={<FaCheck />}
                  disabled={selectedOrders.some(
                    (order) => order.state !== "TAKING_OUT"
                  )}
                  onClick={async () => {
                    await takeOut(selectedOrders.map((order) => order.orderId))
                  }}
                >
                  {client?.role === "SME" ? "픽업 요청" : "출고"}
                </AsyncActionButton>
                <AsyncActionButton
                  kind="secondary"
                  startEnhancer={<ImCross />}
                  disabled={selectedOrders.some((order) => {
                    const canCancel =
                      order.state === "TAKING_OUT" ||
                      (order.state === "PENDING_COLLECT" &&
                        client?.role === "SME")
                    return !canCancel
                  })}
                  onClick={async () => {
                    setCancelingOrderIds(
                      selectedOrders.map((order) => order.orderId)
                    )
                  }}
                >
                  배송 취소
                </AsyncActionButton>
              </div>
            }
          >
            <div className="h-full" />
          </Popover>
        ) : (
          <>
            <Link href="/orders/new" passHref>
              <Button kind="secondary" startEnhancer={<Plus />}>
                배송 요청
              </Button>
            </Link>
            <Link href="/orders/import-excel" passHref>
              <Button startEnhancer={<Upload />}>엑셀 업로드</Button>
            </Link>
          </>
        )}
      </div>
      <div className="flex-1 overflow-clip">
        {(enableShippingTypeFilter ||
          enableShippingPlaceFilter ||
          stationCodeOptions.length > 0) && (
          <div className="flex flex-row items-center">
            {[
              enableShippingTypeFilter && (
                <ShippingTypeFilter
                  key="shippingTypeFilter"
                  option={shippingTypeFilterOption}
                  setOption={setShippingTypeFilterOption}
                />
              ),
              enableShippingPlaceFilter && (
                <ShippingPlaceFilter
                  key="shippingPlaceFilter"
                  shippingPlaces={shippingPlaces}
                  option={shippingPlaceFilterOption}
                  setOption={setShippingPlaceFilterOption}
                />
              ),
              stationCodeOptions.length > 0 && (
                <StationCodeFilter
                  key="stationCodeFilter"
                  option={stationCodeFilterOption}
                  setOption={setStationCodeFilterOption}
                  options={stationCodeOptions}
                />
              ),
            ]
              .filter(Boolean)
              .flatMap((filter, i) => [
                filter,
                <span
                  key={`separator-${i}`}
                  className="mx-3 inline-block h-4 border-r border-gray-400"
                />,
              ])
              .slice(0, -1)}
          </div>
        )}
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => setActiveKey(activeKey as TabKey)}
          overrides={{
            Root: {
              style: {
                height: "100%",
                display: "flex",
                flexDirection: "column",
              },
            },
            TabHighlight: {
              style: {
                backgroundColor: "#2563eb",
              },
            },
          }}
        >
          {orderTabs.map(({ title, key, orders, columns }) => (
            <Tab
              title={`${title} (${orders?.length ?? 0})`}
              key={key}
              overrides={{
                TabPanel: {
                  style: {
                    display: "contents",
                  },
                },
              }}
            >
              <OrderTable
                orders={orders}
                heldLoads={heldLoads}
                regionSets={regionSets}
                onSelectionChange={setSelectedOrders}
                columns={columns}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
      <Modal isOpen={isCreatingInvoice} closeable={false}>
        <ModalHeader>송장 파일 다운로드 중...</ModalHeader>
        <ModalBody>
          <div className="flex justify-center">
            <div id="invoice-progress-bar">0</div>%...
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={createInvoiceOptModal}>
        <ModalHeader>송장 다운로드 옵션 선택</ModalHeader>
        <ModalBody>
          <div className="flex">
            <RadioGroup
              value={createInvoiceOpt}
              onChange={(e) =>
                setCreateInvoiceOpt(e.currentTarget.value as PrintType)
              }
              name="print-type"
            >
              <Radio value="DOWNLOAD">일괄 다운로드</Radio>
              {isTruckCaller ? (
                <Radio value="PRODUCT_CHUNK_DOWNLOAD">
                  최대 100건씩 다운로드
                </Radio>
              ) : (
                <Radio value="CHUNK_DOWNLOAD">100건씩 다운로드</Radio>
              )}
            </RadioGroup>
          </div>
          <div className="text-sm text-red-500">
            <p>
              사용자 컴퓨터 성능 및 환경에 따라 일괄 다운로드는 에러가 발생할 수
              있습니다.
            </p>
            <p>에러 발생시 100건씩 다운로드를 사용해주세요.</p>
            {isTruckCaller && (
              <p className="mt-4">
                같은 주문의 여러 송장은 동일한 파일에 다운로드 되어 한 파일에
                담긴 송장이 100건이 안 될 수 있습니다.
              </p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => setCreateInvoiceOptModal(false)}
          >
            취소
          </ModalButton>
          <ModalButton
            onClick={async () => {
              setCreateInvoiceOptModal(false)
              await onClickCreateInvoiceButton(createInvoiceOpt)
            }}
          >
            다운로드
          </ModalButton>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={!!takingOutOrderIds.length}
        onClose={() => setTakingOutOrderIds([])}
      >
        <ModalHeader>송장 파일 다운로드 완료</ModalHeader>
        <ModalBody>
          방금 송장 출력한 물품들을 바로{" "}
          {client?.role === "SME" ? "픽업" : "출고"} 요청할까요?
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => setTakingOutOrderIds([])}
          >
            {client?.role === "SME" ? "아니요" : "취소"}
          </ModalButton>
          {client?.role === "SME" ? (
            <ModalButton
              kind={KIND.tertiary}
              onClick={() => setTakingOutOrderIds([])}
            >
              반값택배 이용
            </ModalButton>
          ) : null}
          <ModalButton
            onClick={async () => {
              await takeOut(takingOutOrderIds)
              setTakingOutOrderIds([])
            }}
            isLoading={isTakingOut}
          >
            {client?.role === "SME" ? "픽업 요청" : "출고 요청"}
          </ModalButton>
        </ModalFooter>
      </Modal>
      <CancelModal
        isOpen={!!cancelingOrderIds.length}
        onClose={() => setCancelingOrderIds([])}
        onCancel={async (reason) => {
          if (!clientId) {
            return
          }
          await Promise.all(
            cancelingOrderIds.map((orderId) =>
              taker.cancelOrder(clientId, orderId, reason)
            )
          )
          setCancelingOrderIds([])
          clearSelectionRef.current?.()
          toaster.info(<>배송요청이 취소되었습니다.</>, {})
          mutate()
        }}
      />
      <OrderDetailDrawer
        orderId={router.query.id as string}
        onClose={() =>
          router.push(router.pathname, undefined, {
            shallow: true,
          })
        }
      />
    </div>
  )
}
