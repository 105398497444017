import { Invoice } from "@today/api/invoice"
import { PDFDocument } from "pdf-lib"
import saveAs from "file-saver"
import _ from "lodash"
import dayjs from "dayjs"
import pLimit from "p-limit"
import { Order } from "@today/api/taker"

export const CHUNK_SIZE = 100

export type PrintType =
  | "PRINT"
  | "DOWNLOAD"
  | "CHUNK_DOWNLOAD"
  | "PRODUCT_CHUNK_DOWNLOAD"

export async function createInvoicePdf(
  invoice: Invoice,
  clientId: string,
  orders: Order[],
  onCreatedInvoices: (createdOrderIds: string[]) => void,
  fileName: string = "invoices",
  printType: PrintType
): Promise<void> {
  if (printType === "PRINT" && orders.length > CHUNK_SIZE)
    throw Error(
      `바로 인쇄 기능은 ${CHUNK_SIZE}개 이하의 주문을 선택 했을 때만 가능합니다.`
    )

  const printDate = dayjs().format("YYYY-MM-DDTHH:mm")

  const totalPageCount = orders.length
  let chunks: Order[][]
  if (printType === "PRODUCT_CHUNK_DOWNLOAD") {
    ;({ chunks } = orders.reduce<{
      chunks: Order[][]
      counts: number[]
    }>(
      ({ chunks, counts }, order) => {
        const count = order.products.reduce((acc, { count }) => acc + count, 0)
        if (
          chunks.length === 0 ||
          counts[counts.length - 1] + count > CHUNK_SIZE
        ) {
          return {
            chunks: [...chunks, [order]],
            counts: [...counts, count],
          }
        }
        chunks[chunks.length - 1].push(order)
        counts[counts.length - 1] += count
        return { chunks, counts }
      },
      { chunks: [], counts: [] }
    ))
  } else {
    chunks = _.chunk(orders, CHUNK_SIZE)
  }

  // invoice 서버 부하 방지를 위해 p-limit 사용
  const limit = pLimit(10)
  const pdfsToMerge = await Promise.all(
    chunks.map((chunk, index) =>
      limit(() =>
        createInvoicePdfChunk(
          invoice,
          chunk,
          totalPageCount,
          index * CHUNK_SIZE,
          printDate,
          fileName,
          printType
        )
      ).finally(() => {
        // XXX: 다량의 물품 로딩시 React DOM 제어 지연으로 인해 Pool 요청이 늦어지는 현상 발생, 이를 해결하기 위해 DOM 강제 갱신
        const prevProgress = document?.getElementById(
          "invoice-progress-bar"
        )?.textContent
        if (prevProgress) {
          let progressNum =
            parseFloat(prevProgress) + (CHUNK_SIZE / totalPageCount) * 100
          if (progressNum > 100) {
            progressNum = 100
          }
          const progress = progressNum.toFixed(2)
          if (document?.getElementById("invoice-progress-bar")?.textContent) {
            document.getElementById("invoice-progress-bar")!.textContent =
              progress
          }
        }
      })
    )
  )

  // 한번에 다운로드
  if (printType === "DOWNLOAD") {
    const mergedPdf = await PDFDocument.create()

    for (const pdfBytes of pdfsToMerge) {
      const pdf = await PDFDocument.load(pdfBytes)
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
      copiedPages.forEach((page) => mergedPdf.addPage(page))
    }

    const mergedPdfFile = await mergedPdf.save()
    const blob = new Blob([mergedPdfFile], { type: "application/pdf" })

    saveAs(
      blob,
      `${fileName}_${printDate}_[(1-${totalPageCount}/${totalPageCount}]).pdf`
    )
  }
}

async function createInvoicePdfChunk(
  invoice: Invoice,
  chunk: Order[],
  totalPageCount: number,
  index: number,
  printDate: string,
  fileName: string = "invoices",
  print: PrintType
) {
  let retry = 0
  const MAX_RETRY = print ? 1 : 3
  while (true) {
    try {
      const pdf = await invoice.retrieveInvoicePdfByteInternal(
        chunk.map(({ orderId }) => orderId),
        totalPageCount,
        index + 1,
        print === "PRODUCT_CHUNK_DOWNLOAD"
      )
      switch (print) {
        case "DOWNLOAD":
          break
        case "PRINT":
          const windowBlob = new Blob([pdf], { type: "application/pdf" })
          const fileURL = window.URL.createObjectURL(windowBlob)
          const invoiceWindow = await window.open(
            fileURL,
            fileURL.toString(),
            "height=600width=400"
          )
          invoiceWindow!.focus()
          invoiceWindow!.print()
          break
        case "CHUNK_DOWNLOAD":
        case "PRODUCT_CHUNK_DOWNLOAD":
          const fileBlob = new Blob([pdf], { type: "application/pdf" })
          saveAs(
            fileBlob,
            `${fileName}_${printDate}_[(${index + 1}-${
              index + chunk.length
            }/${totalPageCount}]).pdf`
          )
          break
      }
      return pdf
    } catch (e) {
      if (++retry >= MAX_RETRY) {
        throw e
      }
    }
  }
}
