import {
  Client,
  EndUserInfoRequest,
  OrderRequest,
  ShippingPlace,
} from "@today/api/taker"
import BaseBinder, {
  Column,
  Group,
  KeyMerger,
  Merger,
  ValidationResult,
  throwErr,
} from "./common"

export type Row = {
  clientOrderId: string
  receiverName: string
  receiverPhone: string
  receiverAddress: string
  preference: string
  productName: string
  productCount: string
}

export default class Fmj extends BaseBinder<Row> {
  readonly columns: Column<Row>[] = [
    { label: "고유번호", key: "clientOrderId" },
    { label: "수령자", key: "receiverName" },
    { label: "휴대폰", key: "receiverPhone" },
    { label: "주소", key: "receiverAddress" },
    { label: "상세요구사항", key: "preference" },
    { label: "판매처상품명", key: "productName" },
    { label: "수량", key: "productCount" },
  ]
  readonly merger: Merger<Row> = new KeyMerger(["clientOrderId"])
  readonly shouldSupplyShippingPlace = true

  protected validateRow(row: Row, index: number): ValidationResult {
    const throwRowErr = (msg: string) => throwErr(index + 1, msg)
    if (!row.receiverName) throwRowErr("받는이 이름이 입력되지 않았습니다.")
    if (!row.receiverAddress) throwRowErr("받는이 주소가 입력되지 않았습니다.")
    if (!row.receiverPhone)
      throwRowErr("받는이 전화번호가 입력되지 않았습니다.")
    if (!row.productName) throwRowErr("품목명이 입력되지 않았습니다.")
    if (isNaN(parseInt(row.productCount)))
      throwRowErr("수량이 숫자 형태가 아닙니다.")
    if (parseInt(row.productCount) <= 0)
      throwRowErr("수량은 1 이상의 값이어야 합니다.")
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  private checkGroupColumns(
    group: Group<Row>,
    column: keyof Row,
    optional = false
  ) {
    group.rows
      .map((g) => g[column])
      .reduce((prev, v, i) => {
        if (optional && !v) return prev
        if (optional && !prev) return v
        if (prev !== v)
          throwErr(
            group.indices[i] + 1,
            `합포장 오류: ${group.indices[0] + 1}번째 줄과 ${(() => {
              const label = this.columns.find((c) => c.key === column)!.label
              return Array.isArray(label) ? label[0] : label
            })()} 값이 일치하지 않습니다.`
          )
        return v
      })
  }

  protected validateGroup(group: Group<Row>): ValidationResult {
    if (!group.rows.length) throw new Error("no rows in merged group")
    this.checkGroupColumns(group, "receiverName")
    this.checkGroupColumns(group, "receiverAddress")
    this.checkGroupColumns(group, "receiverPhone")
    this.checkGroupColumns(group, "clientOrderId")
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  protected useSkipTakeOut(): boolean {
    return false
  }

  protected convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    shippingPlace?: ShippingPlace | ShippingPlace[]
  ): OrderRequest {
    if (Array.isArray(shippingPlace))
      throw new Error("출고지 오류가 발생했습니다. 담당자에게 연락 바랍니다.")
    if (!shippingPlace) throw new Error("출고지가 선택되지 않았습니다.")
    function find(key: keyof Row) {
      return group.rows.map((g) => g[key]).find((e) => !!e)
    }
    const isReturning = false // FMJ는 반품을 투데이로 수행하지 않음
    const sender: EndUserInfoRequest = {
      shippingPlaceId: shippingPlace.id,
      name: client.name,
      phone: "-",
      address: `${shippingPlace.addressInfo.streetBaseAddress} ${shippingPlace.addressInfo.streetDetailAddress}`,
      accessMethod: "",
      preference: "",
    }
    const receiver: EndUserInfoRequest = {
      name: find("receiverName")!,
      phone: find("receiverPhone")!,
      address: find("receiverAddress")!,
      accessMethod: find("preference"),
      preference: find("preference"),
    }
    return {
      clientOrderId: find("clientOrderId"),
      sender: isReturning ? receiver : sender,
      receiver: isReturning ? sender : receiver,
      products: group.rows.flatMap((row) =>
        row.productName
          .split(";")
          .filter((name) => name)
          .map((name) => ({
            name,
            price: 0,
            sellerName: "에프엠제이",
            count: 1,
            fragile: false,
          }))
      ),
      returningInfo: null,
    }
  }
}
