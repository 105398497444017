import { atom, AtomEffect, useRecoilState } from "recoil"
import { useCallback } from "react"
import { v4 as uuid4 } from "uuid"

const key = "printedOrderIds"

const printedOrderIdsState = atom<Set<string>>({
  key: `${key}/${uuid4()}`,
  default: new Set(),
  effects: [localStorageEffect(key)],
})

export function usePrintedOrderIds() {
  const [printedOrderIds, setPrintedOrderIds] =
    useRecoilState(printedOrderIdsState)
  const markAsPrinted = useCallback(
    (newOrderIds: string[]) =>
      setPrintedOrderIds(
        (prevOrderIds) => new Set([...prevOrderIds, ...newOrderIds])
      ),
    [setPrintedOrderIds]
  )
  return { printedOrderIds, markAsPrinted }
}

// TODO: 코드 중복이 있지만 Set을 처리하는 로직이 있어서 둔다. localForage로 전환할 경우 migration이 필요하다.
function localStorageEffect(key: string): AtomEffect<Set<string>> {
  return ({ setSelf, onSet }) => {
    if (typeof localStorage === "undefined") return
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(new Set(JSON.parse(savedValue)))
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify([...newValue]))
    })
  }
}
