import {
  Client,
  EndUserInfoRequest,
  OrderRequest,
  ShippingPlace,
} from "@today/api/taker"
import BaseBinder, {
  Column,
  Group,
  KeyMerger,
  Merger,
  NoMerger,
  throwErr,
  ValidationResult,
} from "./common"
import { pick } from "lodash"

type PickUpTruckType = "다마스" | "라보" | "1톤트럭"
export const pickUpTruckTypes: PickUpTruckType[] = ["다마스", "라보", "1톤트럭"]

const pickUpTruckTypesCode = Object.fromEntries([
  ["다마스", "DAMAS"],
  ["라보", "LABO"],
  ["1톤트럭", "1_TON"],
])

export function getPickUpTruckTypeCode(type: PickUpTruckType) {
  return pickUpTruckTypesCode[type]
}

type YesOrNo = "Y" | "N"

export type Row = {
  clientOrderId: string
  productCustomerRequestTime: string
  pickingLocation: string
  senderName: string
  receiverName: string
  receiverPhone: string
  receiverAddress: string
  productName: string
  productCount: string
  pickUpTruckType: string
  pickUpTruckCount: string
  accessMethod: string
  preference: string
  sellerName: string
  channel: string
  productCategory: string
  productFragile: YesOrNo
  productPrice: string
  productCode: string
}

export default class TruckCaller extends BaseBinder<Row> {
  readonly columns: Column<Row>[] = [
    { label: "고객 주문번호", key: "clientOrderId" },
    { label: "고객 주문일시", key: "productCustomerRequestTime" },
    { label: "피킹 위치", key: "pickingLocation" },
    { label: "발송자명", key: "senderName" },
    { label: "고객명", key: "receiverName" },
    { label: "고객 전화번호", key: "receiverPhone" },
    { label: "고객 주소", key: "receiverAddress" },
    { label: "상품명", key: "productName" },
    { label: "박스 수량", key: "productCount" },
    { label: "차량 선택", key: "pickUpTruckType" },
    { label: "차량 대 수", key: "pickUpTruckCount" },
    { label: "고객 건물 출입방법", key: "accessMethod" },
    { label: "고객 요청사항", key: "preference" },
    { label: "셀러명", key: "sellerName" },
    { label: "판매채널", key: "channel" },
    { label: "상품 카테고리", key: "productCategory" },
    { label: "상품 깨짐주의", key: "productFragile" },
    { label: "상품 가액", key: "productPrice" },
    { label: "상품 코드", key: "productCode" },
  ]
  readonly merger: Merger<Row> = new KeyMerger(["clientOrderId"])
  readonly shouldSupplyShippingPlace: boolean = true
  // TODO: Client.phoneNumber 필드 생기기 전까지 사용하는 임시 필드
  readonly senderPhone: string = "-"

  protected validateRow(row: Row, index: number): ValidationResult {
    const throwRowErr = (msg: string) => throwErr(index + 1, msg)
    if (!row.receiverName) throwRowErr("수취인 성명이 입력되지 않았습니다.")
    if (!row.receiverAddress) throwRowErr("수취인 주소가 입력되지 않았습니다.")
    if (!row.receiverPhone)
      throwRowErr("수취인 전화번호가 입력되지 않았습니다.")
    if (!row.productName) throwRowErr("품목명이 입력되지 않았습니다.")
    if (isNaN(parseInt(row.productCount)))
      throwRowErr("수량이 숫자 형태가 아닙니다.")
    if (parseInt(row.productCount) <= 0)
      throwRowErr("수량은 1 이상의 값이어야 합니다.")
    if (row.pickUpTruckType.trim() !== "") {
      if (pickUpTruckTypes.includes(row.pickUpTruckType as PickUpTruckType)) {
        if (row.pickUpTruckCount === "")
          throwRowErr("차량 대 수가 입력되지 않았습니다.")
        if (parseInt(row.pickUpTruckCount) < 1)
          throwRowErr("차량 대 수는 1대 이상이어야 합니다.")
      } else {
        throwRowErr("차량 선택은 다마스 / 라보 / 1톤트럭 중 하나여야 합니다.")
      }
    }

    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  protected validateGroup(group: Group<Row>): ValidationResult {
    if (!group.rows.length) throw new Error("no rows in merged group")
    const hasPickUpTruck = group.rows.some(
      ({ pickUpTruckType, pickUpTruckCount }) => {
        return pickUpTruckType.trim() !== "" && parseInt(pickUpTruckCount) > 0
      }
    )
    if (!hasPickUpTruck)
      throwErr(group.indices[0] + 1, "차량 정보가 입력되지 않았습니다.")
    // TODO
    return {
      errors: [],
      warnings: [],
    }
  }

  protected useSkipTakeOut(): boolean {
    return false
  }

  protected convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    shippingPlace?: ShippingPlace | ShippingPlace[]
  ): OrderRequest {
    if (Array.isArray(shippingPlace))
      throw new Error("출고지 오류가 발생했습니다. 담당자에게 연락 바랍니다.")
    if (!shippingPlace) throw new Error("출고지가 선택되지 않았습니다.")
    function find(key: keyof Row) {
      return group.rows.map((g) => g[key]).find((e) => !!e)
    }

    // 차량 입력은 한 줄에만 진행하지만, 여러 줄 입력된 경우 수량 > 차량 용량 순으로 비교하여 많고 큰 차를 배정한다.
    const [pickUpTruckType, pickUpTruckCount] = group.rows
      .filter(
        ({ pickUpTruckType, pickUpTruckCount }) =>
          pickUpTruckType.trim() !== "" && parseInt(pickUpTruckCount) > 0
      )
      .map<[string, number]>(({ pickUpTruckType, pickUpTruckCount }) => [
        pickUpTruckType,
        parseInt(pickUpTruckCount),
      ])
      .reduce(([prevType, prevCount], [type, count]) => {
        if (count > prevCount) return [type, count]
        if (
          pickUpTruckTypes.findIndex((v) => v === type) >
          pickUpTruckTypes.findIndex((v) => v === prevType)
        )
          return [type, count]
        return [prevType, prevCount]
      })

    // Order payload 준비
    const sender: EndUserInfoRequest = {
      shippingPlaceId: shippingPlace.id,
      name: find("senderName") ?? client.name,
      phone: this.senderPhone,
      address: `${shippingPlace.addressInfo.streetBaseAddress} ${shippingPlace.addressInfo.streetDetailAddress}`,
      accessMethod: "",
      preference: "",
    }
    const receiver: EndUserInfoRequest = {
      name: find("receiverName")!,
      phone: find("receiverPhone")!,
      address: find("receiverAddress")!,
      accessMethod: find("accessMethod"),
      preference: find("preference"),
    }
    return {
      clientOrderId: find("clientOrderId"),
      sender,
      receiver,
      products: group.rows.map((row) => ({
        name: row.productName,
        price: parseInt(row.productPrice ?? "0"),
        sellerName: find("sellerName"),
        clientProductId: row.productCode.toString(),
        count: +row.productCount,
        fragile: row.productFragile === "Y",
        pickingLocation: row.pickingLocation,
      })),
      returningInfo: null,
      developerPayload: JSON.stringify({
        "@today": {
          channel: find("channel"),
          shipping_fee: {
            pick_up_trucks: [
              {
                type: getPickUpTruckTypeCode(
                  pickUpTruckType as PickUpTruckType
                ),
                count: pickUpTruckCount,
              },
            ],
          },
        },
      }),
    }
  }
}
