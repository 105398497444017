import { KeyMerger, Merger } from "./common"
import Legacy, { Row } from "./legacy"

export default class DasanGlobal extends Legacy {
  readonly merger: Merger<Row> = new KeyMerger([
    "receiverName",
    "receiverPhone",
    "receiverAddress",
  ])
}
