import { CLIENTS_USING_STATION_CODE, StationCode } from "@today/lib"
import { OrderTab, TabKey } from "./types"
import { useMemo } from "react"
import { Client } from "@today/api/taker"

const HNS_MALL_CLIENT_IDS = ["131973694074", "583025091234"] // 홈앤쇼핑
const ROADMAN_B2C_CLIENT_IDS = ["124678982251", "597843884375"] // 로드맨 B2C

export function useCustomColumns(
  client: Client | undefined,
  baseOrderTabs: OrderTab[]
) {
  return useMemo(() => {
    if (!client)
      return {
        stationCodeOptions: [],
        orderTabs: [],
      }
    const clientId = client.clientId

    const orderTabs = baseOrderTabs.map((tab) => ({
      title: tab.title,
      key: tab.key,
      orders: tab.orders ? [...tab.orders] : tab.orders,
      columns: [...tab.columns],
    }))

    // XXX: 정류소 코드를 표의 특정 컬럼에 표시하고 싶은 경우
    // Taker의 clientIDsForLastMileInfoExposure 에 고객사를 등록해야 한다.
    const stationCodeOptions: (StationCode | "X")[] = []
    if (
      CLIENTS_USING_STATION_CODE.includes(clientId) ||
      HNS_MALL_CLIENT_IDS.includes(clientId)
    ) {
      stationCodeOptions.push("A", "B", "C", "D")

      if (CLIENTS_USING_STATION_CODE.includes(clientId)) {
        stationCodeOptions.push("X")
      }

      const activatedStates: TabKey[] = ["PENDING_COLLECT", "TAKING_OUT"]
      orderTabs.forEach((t) => {
        if (t.key === "ALL") {
          t.columns.splice(
            t.columns.indexOf("CUSTOMER_PREFERENCE") + 1,
            0,
            "STATION_CODE"
          )
        } else if (activatedStates.includes(t.key)) {
          t.columns.push("STATION_CODE")
        }
      })
    }

    // 소상공인(전국택배)은 송장번호 발급 택배사와 deliveryClass 표시
    // TODO: 소상공인이 모두 전국택배를 하는 것은 아니지만, 구현의 편의와 이후 확장을 고려하여 결정함
    if (client.role === "SME" || ROADMAN_B2C_CLIENT_IDS.includes(clientId)) {
      orderTabs.forEach((t) => {
        t.columns.push("INVOICE_NUMBER_OWNER")
        t.columns.push("DELIVERY_CLASS")
      })
    }

    // 홈앤쇼핑 커스텀 컬럼 대응
    // https://vtov.atlassian.net/browse/SW-5013
    if (HNS_MALL_CLIENT_IDS.includes(clientId)) {
      orderTabs.forEach((t) => {
        t.columns.push("HNS_MALL_PRODUCT_CODE")
      })
    }

    return {
      stationCodeOptions,
      orderTabs,
    }
  }, [client, baseOrderTabs])
}
