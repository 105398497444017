import { Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"
import dayjs from "dayjs"

export function getHoldTimeOfHoldingDeliveryOrder(
  order: Order,
  heldLoadsById: Record<string, Load>
): string | null {
  const load: Load | undefined = heldLoadsById[order.orderId]
  const { holdTime } = load?.holdDeliveryInfo ?? {}
  return holdTime ? dayjs(holdTime).format("YYYY-MM-DD HH:mm:ss") : null
}
