import { ALIGN, Radio, RadioGroup } from "baseui/radio"
import { useState } from "react"

type ShippingTypeFilterOption = "ALL" | "DELIVERY" | "LM_PICK_UP"

type ShippingTypeFilterProps = {
  option: ShippingTypeFilterOption
  setOption: (opt: ShippingTypeFilterOption) => void
}

export function useShippingTypeFilter() {
  const [option, setOption] = useState<ShippingTypeFilterOption>("ALL")
  return { option, setOption }
}

export function ShippingTypeFilter({
  option,
  setOption,
}: ShippingTypeFilterProps) {
  return (
    <>
      <div className="ml-2 font-bold">운송 타입 필터</div>
      <div className="ml-2">
        <RadioGroup
          value={option}
          onChange={(e) =>
            setOption(e.currentTarget.value as ShippingTypeFilterOption)
          }
          name="shipping-type"
          align={ALIGN.horizontal}
        >
          <Radio value="ALL">전체</Radio>
          <Radio value="DELIVERY">배송물품</Radio>
          <Radio value="LM_PICK_UP">회수물품</Radio>
        </RadioGroup>
      </div>
    </>
  )
}
