import { GA_TRACKING_ID } from "../config"

export const trackingId = GA_TRACKING_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  if (typeof window.gtag == "undefined") return

  window.gtag("config", GA_TRACKING_ID as string, {
    page_path: url,
  })
}

interface GTagEventProps {
  action: string
  category: string
  label: string
  value: number
}

export const event = (
  { action, category, label, value }: GTagEventProps,
  eventParameters?: Record<string, string>
) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
    ...(eventParameters ? { eventParameters } : {}),
  })
}
