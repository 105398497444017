import { TaxInvoice } from "@today/api/taker"

export function getTaxInvoiceStateName(state: TaxInvoice["state"]) {
  switch (state) {
    case "INITIAL":
      return "발급 대기"
    case "SIGNED":
      return "발급 대기"
    case "PENDING":
      return "발급 중"
    case "SUCCESS":
      return "발급 완료"
    case "FAILED":
      return "발급 실패"
    case "CANCELED":
      return "발급 취소"
    default:
      return state
  }
}
