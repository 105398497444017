import {
  Client,
  CreateOrderRequest,
  Order,
  ShippingPlace,
} from "@today/api/taker"
import { Load } from "@today/api/tracker"
import { getShippingTypes, isLegacyClient, ordersToPod } from ".."

export type Column<T> = {
  label: string | string[]
  key: keyof T
  optional?: boolean
}

export type BaseRow = {
  [k: string]: string
}

export type BaseRowWithIndex = BaseRow & { index: number }

export type ParseResult<Row> = {
  rows: Row[]
  errors: string[]
  warnings: string[]
}

export type ValidationResult = {
  errors: string[]
  warnings: string[]
}

export interface Binder<Row extends BaseRow> {
  // 엑셀파일에 출고지 정보가 없어 요청 생성 시 출고지 정보를 주입받아야 하는지 여부
  readonly shouldSupplyShippingPlace: boolean
  // 엑셀 각 컬럼명과 바인더 내부에서 관리하는 영문 키 매핑
  // 컬럼 definition이다.
  readonly columns: Column<Row>[]
  // 머릿행 제외 각 행을 파싱한 결과
  rows: Row[] | null
  // 합포장 상품끼리 묶인 그룹들
  readonly groups: Group<Row>[] | null

  // 엑셀 데이터를 넣어 파싱한다.
  parse(json: { [key: string]: string }[]): ParseResult<Row>
  // Order List를 엑셀 데이터로 변환한다.
  export(
    client: Client,
    orders: Order[],
    shippingPlaces: ShippingPlace[],
    heldLoads: Load[],
    linePerProduct?: boolean
  ): string[][]
  // 파싱을 스킵하고 파싱된 결과를 갖고 초기화한다.
  resetWithRows(rows: Row[]): void
  // rows와 groups 정합성을 검증한다. 없으면 하지 않는다. 문제 발생 시 에러를 throw 한다.
  // createOrderRequests 내부에서 merge 직후 자동 호출된다.
  validate(allowedShippingTypes?: Order["shippingType"][]): ValidationResult
  // 등록 요청을 생성한다.
  // 이것이 불리면 내부적으로 merge 작업을 수행하여 groups가 세팅되게 된다.
  createOrderRequests(
    client: Client,
    shippingPlace?: ShippingPlace | ShippingPlace[],
    sellerName?: string,
    senderAddress?: string
  ): {
    skipTakeOut: boolean
    requests: CreateOrderRequest[]
    errors?: string[]
  }
}

// 엑셀에서 각 행은 상품을 의미하는 경우가 많다. 합포장 할 행끼리 묶은 것을 그룹이라 한다.
// 합포장 상품끼리 묶는 행위를 본 코드 상에서는 merge라 부른다.
export type Group<Row extends BaseRow> = {
  indices: number[]
  rows: Row[]
}

// 기본 바인더. 여기서 미구현된 메서드들만 구현하면 각 엑셀파일별 바인더를 쉽게 생성할 수 있다.
export default abstract class BaseBinder<Row extends BaseRow>
  implements Binder<Row>
{
  abstract readonly shouldSupplyShippingPlace: boolean
  abstract readonly columns: Column<Row>[]
  abstract readonly merger: Merger<Row>

  private _rows: Row[] | null = null
  get rows(): Row[] | null {
    return this._rows
  }
  set rows(rows) {
    this._rows = rows
    this._groups = null
  }

  private _groups: Group<Row>[] | null = null
  get groups(): Group<Row>[] | null {
    return (this._groups ??= this.rows ? this.merger.merge(this.rows) : null)
  }

  parse(json: { [key: string]: string }[]): ParseResult<Row> {
    const errors: string[] = []
    const warnings: string[] = []
    if (!json.length) {
      errors.push("파일이 비어 있습니다.")
      return {
        rows: [],
        errors,
        warnings,
      }
    }

    const firstRow = json[0]
    const givenColumns = new Set(
      Object.keys(firstRow).filter((key) => !key.startsWith("__EMPTY"))
    )
    const knownColumns = new Set(
      this.columns
        .map(({ label }) => (Array.isArray(label) ? label : [label]))
        .flat()
    )
    const unknownColumns = Array.from(givenColumns).filter(
      (x) => !knownColumns.has(x)
    )
    if (unknownColumns.length) {
      warnings.push(
        [
          "업로드한 파일에 알 수 없는 열이 포함되어 있습니다.",
          unknownColumns.join(", "),
        ].join("\n")
      )
    }
    const missingCols = Array.from(
      this.columns
        .filter(({ optional }) => !optional)
        .map(({ label }) => new Set(Array.isArray(label) ? label : [label]))
    )
      .filter((x) => ![...givenColumns].some((label) => x.has(label)))
      .map((set) => [...set][0])
    if (missingCols.length) {
      errors.push(
        [
          "업로드한 파일에서 다음과 같은 열을 찾을 수 없습니다.",
          missingCols.join(", "),
        ].join("\n")
      )
    }
    const duplicatedCols = this.columns
      .map(({ label }) => new Set(Array.isArray(label) ? label : [label]))
      .filter(
        (x) =>
          [...givenColumns].reduce(
            (acc, label) => (x.has(label) ? acc + 1 : acc),
            0
          ) > 1
      )
      .map((set) => [...set][0])
    if (duplicatedCols.length) {
      warnings.push(
        [
          "업로드한 파일에서 같은 열이 중복으로 정의되어 있습니다.",
          duplicatedCols.join(", "),
        ].join("\n")
      )
    }
    if (errors.length) {
      return {
        rows: [],
        errors,
        warnings,
      }
    }

    // 각 행 데이터 바인딩
    const rows = json.map(
      (object) =>
        Object.fromEntries(
          this.columns.map((col) => [
            col.key,
            (Array.isArray(col.label) ? col.label : [col.label]).reduce(
              (res, label) => {
                return object[label]?.toString() ?? res
              },
              ""
            ),
          ])
        ) as Row
    )
    this.rows = rows
    return {
      rows,
      errors,
      warnings,
    }
  }

  export(
    client: Client,
    orders: Order[],
    shippingPlaces: ShippingPlace[],
    heldLoads: Load[],
    linePerProduct?: boolean
  ): string[][] {
    return ordersToPod(
      client,
      orders,
      shippingPlaces,
      heldLoads,
      linePerProduct
    )
  }

  resetWithRows(rows: Row[]): void {
    this.rows = rows
  }

  validate(
    allowedShippingTypes?: Order["shippingType"][],
    checkSenderAddress?: boolean
  ): ValidationResult {
    const errors: string[] = []
    if (this.rows) {
      errors.push(
        ...this.rows.flatMap(
          (row, i) =>
            this.validateRow(row, i, allowedShippingTypes, checkSenderAddress)
              .errors
        )
      )
    }
    if (this.groups) {
      errors.push(
        ...this.groups.flatMap((group) => this.validateGroup(group).errors)
      )
    }
    return {
      errors,
      warnings: [],
    }
  }

  protected abstract validateRow(
    row: Row,
    index: number,
    allowedShippingTypes?: Order["shippingType"][],
    checkSenderAddress?: boolean
  ): ValidationResult
  protected abstract validateGroup(group: Group<Row>): ValidationResult
  protected abstract useSkipTakeOut(): boolean

  createOrderRequests(
    client: Client,
    shippingPlace?: ShippingPlace,
    sellerName?: string,
    senderAddress?: string
  ): {
    skipTakeOut: boolean
    requests: CreateOrderRequest[]
    errors?: string[]
  } {
    if (!this.rows || !this.groups)
      throw new Error(
        "일시적인 에러가 발생했습니다. 페이지를 새로고침 해주세요."
      )
    if (this.shouldSupplyShippingPlace && !shippingPlace)
      throw new Error("출고지 정보가 전달되지 않았습니다.")
    const allowedShippingTypes = getShippingTypes(client)
    const { errors } = this.validate(
      isLegacyClient(client) ? undefined : allowedShippingTypes,
      !senderAddress && !shippingPlace
    )
    const requests = this.groups.map((group) =>
      this.convertGroupToRequest(
        group,
        client,
        shippingPlace,
        sellerName,
        senderAddress
      )
    )
    return {
      skipTakeOut: this.useSkipTakeOut(),
      requests,
      errors,
    }
  }

  protected abstract convertGroupToRequest(
    group: Group<Row>,
    client: Client,
    shippingPlace?: ShippingPlace | ShippingPlace[],
    sellerName?: string,
    senderAddress?: string
  ): CreateOrderRequest
}

export function throwErr(line: number, msg: string) {
  throw new Error(`${line}번째 줄: ${msg}`)
}

// 머지 작업 (합포장 행들 합치는 것) 수행을 위한 인터페이스.
// 각 구현체마다 머지 방식이 다르다.
// 머지 정책은 바인더에서 composition으로 갖고 있다.
export interface Merger<Row extends BaseRow> {
  merge(rows: Row[]): Group<Row>[]
}

// 합포장이 필요 없는 경우 사용
export class NoMerger<Row extends BaseRow> implements Merger<Row> {
  merge(rows: Row[]): Group<Row>[] {
    return rows.map((e, i) => ({ indices: [i], rows: [e] }))
  }
}

// 바로 윗 행과 합포장 처리시킬 수 있는 형식의 엑셀 포맷 지원.
// 바로 윗 행과 합포장 처리하려면 주어진 합포장 컬럼에 체크표시를 해야 한다.
export class CheckMerger<Row extends BaseRow> implements Merger<Row> {
  private readonly mergeKey: keyof Row
  private readonly mergeValues?: string[]

  constructor(mergeKey: keyof Row, mergeValues: string[]) {
    this.mergeKey = mergeKey
    this.mergeValues = mergeValues
  }

  shouldMerged(row: Row): boolean {
    return !this.mergeValues || this.mergeValues.includes(row[this.mergeKey])
  }

  merge(rows: Row[]): Group<Row>[] {
    if (!rows.length) return []
    if (this.shouldMerged(rows[0])) {
      throwErr(1, `첫번째 데이터 행은 합포장할 수 없습니다.`)
    }
    const groups: Group<Row>[] = []
    rows.forEach((row, i) => {
      if (this.shouldMerged(row)) {
        groups[groups.length - 1].rows.push(row)
        groups[groups.length - 1].indices.push(i)
      } else if (!row[this.mergeKey!]) {
        throwErr(i + 1, `합포장 여부가 허용되지 않은 값으로 설정되었습니다.`)
      } else {
        groups.push({ indices: [i], rows: [row] })
      }
    })
    return groups
  }
}

// 고객 주문 ID와 같은 특정 키가 같으면 합포장 처리 되는 방식.
// 인접한 행이 아니어도 상관 없다.
export class KeyMerger<Row extends BaseRow> implements Merger<Row> {
  private readonly mergeKeys: (keyof Row)[]

  constructor(mergeKey: (keyof Row)[]) {
    this.mergeKeys = mergeKey
  }

  merge(rows: Row[]): Group<Row>[] {
    if (!rows.length) return []
    const groups: Group<Row>[] = []
    const indices: { [key: string]: number } = {}
    rows.forEach((row, i) => {
      const keys = this.mergeKeys.map((k) => row[k])
      const key = keys.join(";")
      if (keys.every((k) => k.length === 0)) {
        groups.push({ indices: [i], rows: [row] })
      } else if (indices[key] !== undefined) {
        groups[indices[key]].rows.push(row)
        groups[indices[key]].indices.push(i)
      } else {
        indices[key] = groups.length
        groups.push({ indices: [i], rows: [row] })
      }
    })
    return groups
  }
}
