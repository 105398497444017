import { FaSpinner } from "react-icons/fa"
import { Client, ListShippingPlacesResponse, Order } from "@today/api/taker"
import { Load, RegionSet } from "@today/api/tracker"
import { useUserInfo } from "@today/auth"
import {
  formatInvoiceNumber,
  getCustomer,
  getDeliveryClassName,
  getForwardingInvoiceNumber,
  getFullAddress,
  getHoldTimeOfHoldingDeliveryOrder,
  getOrderStateName,
} from "@today/lib"
import { getStationCode } from "../../utils"
import { Checkbox } from "baseui/checkbox"
import { Check, Delete } from "baseui/icon"
import { StyledLink } from "baseui/link"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import dayjs from "dayjs"
import keyBy from "lodash/keyBy"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { usePrintedOrderIds } from "../../state"
import { useSWRWithAuth } from "@today/api"

export type OrderTableProps = {
  orders?: Order[]
  heldLoads?: Load[]
  regionSets?: RegionSet[]
  isLoading?: boolean
  emptyMessage?: string
  columns: ColumnName[]
  onSelectionChange?: (orders: Order[]) => void
}

type ColumnName =
  | "INVOICE_NUMBER"
  | "INVOICE_NUMBER_OWNER"
  | "DELIVERY_CLASS"
  | "HAS_PRINTED_INVOICE"
  | "DETAIL_BUTTON"
  | "ORIGINAL_INVOICE_NUMBER"
  | "STATE"
  | "SHIPPING_PLACE"
  | "SENDER_SHIPPING_PLACE"
  | "RECEIVER_SHIPPING_PLACE"
  | "CLIENT_ORDER_ID"
  | "PRODUCT_NAME"
  | "CUSTOMER_NAME"
  | "CUSTOMER_PHONE"
  | "CUSTOMER_POSTAL_CODE"
  | "CUSTOMER_ADDRESS"
  | "CUSTOMER_PREFERENCE"
  | "ORDER_TIME"
  | "TAKE_OUT_TIME"
  | "PICK_UP_TIME"
  | "DELIVERY_TIME"
  | "QUIT_TIME"
  | "HOLD_DELIVERY_TIME"
  | "STATION_CODE"
  | "SHIPPING_FEE"
  | "TOTAL_PRODUCT_COUNT"
  | "HNS_MALL_PRODUCT_CODE"

const SORT_KEYS = {
  productName: "PRODUCT_NAME",
  customerAddress: "CUSTOMER_ADDRESS",
  stationCode: "STATION_CODE",
  state: "STATE",
  hnsMallProductCode: "HNS_MALL_PRODUCT_CODE",
}

export function OrderTable({
  orders,
  heldLoads,
  regionSets,
  isLoading,
  emptyMessage,
  columns: columnNames,
  onSelectionChange,
}: OrderTableProps) {
  const router = useRouter()
  const { clientId } = useUserInfo()
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { data } = useSWRWithAuth<ListShippingPlacesResponse>(
    clientId && `/api/clients/${clientId}/shipping-places`
  )
  const shippingPlaces = data?.shippingPlaces ?? []
  const { printedOrderIds } = usePrintedOrderIds()
  const [selectedOrderIds, setSelectedOrderIds] = useState<Set<string>>(
    new Set([])
  )
  const heldLoadsById = keyBy(heldLoads, (load) => load.id)

  const getSortKey = useCallback(
    (order: Order, columnId: string, regionSets: RegionSet[]): string => {
      switch (columnId) {
        case SORT_KEYS.productName:
          return order.products[0].name
        case SORT_KEYS.customerAddress:
          const customer = order.returningInfo ? order.sender : order.receiver
          return getFullAddress(customer)
        case SORT_KEYS.stationCode:
          return getStationCode(order, regionSets)
        case SORT_KEYS.state:
          return heldLoadsById[order.orderId]
            ? "배송 보류"
            : getOrderStateName(
                order.state,
                !order.sender.shippingPlaceId,
                undefined,
                client?.role
              )
        case SORT_KEYS.hnsMallProductCode:
          return order.note
      }
      return ""
    },
    [client, heldLoadsById]
  )

  const [sortColumn, setSortColumn] = useState<string>()
  const [sortAsc, setSortAsc] = useState(true)
  const sortedOrders = useMemo(() => {
    if (!orders) return undefined
    if (!sortColumn || !regionSets) return orders
    return [...orders].sort((a: Order, b: Order) => {
      const left = sortAsc ? a : b
      const right = sortAsc ? b : a
      const leftValue = getSortKey(left, sortColumn, regionSets)
      const rightValue = getSortKey(right, sortColumn, regionSets)
      return leftValue.localeCompare(rightValue, "en", {
        numeric: true,
        sensitivity: "base",
      })
    })
  }, [orders, sortColumn, regionSets, sortAsc, getSortKey])
  const handleSort = (id: string) => {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc)
    } else {
      setSortColumn(id)
      setSortAsc(true)
    }
  }
  useEffect(() => {
    if (!sortedOrders) return
    const orders = sortedOrders.filter((order) =>
      selectedOrderIds.has(order.orderId)
    )
    onSelectionChange?.(orders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectionChange, selectedOrderIds, JSON.stringify(sortedOrders)])

  const hasAny = !!orders?.length
  const hasAll = hasAny && selectedOrderIds.size === orders.length
  const hasSome = hasAny && selectedOrderIds.size > 0
  return (
    <div className="mb-16 h-full flex-1 overflow-y-scroll">
      <TableBuilder
        data={sortedOrders}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? "ASC" : "DESC"}
        onSort={handleSort}
        isLoading={isLoading ?? !orders}
        emptyMessage={emptyMessage ?? "조건에 맞는 배송건이 없습니다."}
        overrides={{
          Root: {
            style: {
              height: "75vh", // XXX: 스크롤 사라지는 문제 임시 해결 https://vtovtov.slack.com/archives/C03CTCE8T8C/p1718844980115199
              maxHeight: "100%",
            },
          },
        }}
      >
        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: "1%" } },
            TableBodyCell: { style: { width: "1%" } },
          }}
          header={
            <Checkbox
              checked={hasAll}
              isIndeterminate={!hasAll && hasSome}
              onChange={() => {
                if (hasAll) {
                  setSelectedOrderIds(new Set())
                } else {
                  if (!orders) return
                  setSelectedOrderIds(
                    new Set(orders.map((order) => order.orderId))
                  )
                }
              }}
            />
          }
        >
          {(order: Order) => (
            <Checkbox
              name={order.orderId}
              checked={selectedOrderIds.has(order.orderId)}
              onChange={(event) => {
                const { name, checked } = event.currentTarget
                if (checked) {
                  const newIds = new Set(selectedOrderIds)
                  newIds.add(order.orderId)
                  setSelectedOrderIds(newIds)
                } else {
                  const newIds = new Set(selectedOrderIds)
                  newIds.delete(order.orderId)
                  setSelectedOrderIds(newIds)
                }
              }}
            />
          )}
        </TableBuilderColumn>
        {columnNames.includes("INVOICE_NUMBER") && (
          <TableBuilderColumn header="송장번호">
            {(order: Order) => {
              if (
                order.deliveryClass === "FORWARDING_24" ||
                (order.deliveryClass === "RETURNING" && client?.role === "SME")
              ) {
                const forwardingInvoiceNumber =
                  getForwardingInvoiceNumber(order)

                return (
                  <Link
                    href={`${router.pathname}?id=${order.orderId}`}
                    passHref
                  >
                    <StyledLink>
                      {forwardingInvoiceNumber
                        ? formatInvoiceNumber(forwardingInvoiceNumber)
                        : "송장번호 발급 전"}
                    </StyledLink>
                  </Link>
                )
              } else {
                return (
                  <Link
                    href={`${router.pathname}?id=${order.orderId}`}
                    passHref
                  >
                    <StyledLink>
                      {formatInvoiceNumber(order.invoiceNumber)}
                    </StyledLink>
                  </Link>
                )
              }
            }}
          </TableBuilderColumn>
        )}
        {columnNames.includes("INVOICE_NUMBER_OWNER") && (
          <TableBuilderColumn header="송장번호 발급 택배사">
            {(order: Order) =>
              order.deliveryClass === "FORWARDING_24" ||
              (order.deliveryClass === "RETURNING" && client?.role === "SME")
                ? order.forwardingInfo?.deliveryOrganizationName
                : "투데이"
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("DELIVERY_CLASS") && (
          <TableBuilderColumn header="서비스 구분">
            {(order: Order) => getDeliveryClassName(order.deliveryClass)}
          </TableBuilderColumn>
        )}
        {columnNames.includes("HAS_PRINTED_INVOICE") && (
          <TableBuilderColumn header="송장 출력 여부">
            {(order: Order) =>
              printedOrderIds.has(order.orderId) ? (
                <Check size={20} color="green" />
              ) : (
                <Delete size={20} color="red" />
              )
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("ORIGINAL_INVOICE_NUMBER") && (
          <TableBuilderColumn header="원송장번호">
            {(order: Order) =>
              formatInvoiceNumber(order.returningInfo?.invoiceNumber ?? "")
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("DELIVERY_CLASS") && (
          <TableBuilderColumn header="배송 분류">
            {(order: Order) => (order.returningInfo ? "반품" : "일반")}
          </TableBuilderColumn>
        )}
        {columnNames.includes("STATE") && (
          <TableBuilderColumn header="배송 상태" id={SORT_KEYS.state} sortable>
            {(order: Order) =>
              heldLoadsById[order.orderId]
                ? "배송 보류"
                : getOrderStateName(order.state, !order.sender.shippingPlaceId)
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("SHIPPING_PLACE") && (
          <TableBuilderColumn header="출고지">
            {(order: Order) =>
              shippingPlaces.find(
                (sp) =>
                  sp.id ===
                  (order.returningInfo ? order.receiver : order.sender)
                    .shippingPlaceId
              )?.name ?? ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("SENDER_SHIPPING_PLACE") && (
          <TableBuilderColumn header="보낸이 출고지">
            {(order: Order) =>
              shippingPlaces.find(
                (sp) => sp.id === order.sender.shippingPlaceId
              )?.name ?? ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("RECEIVER_SHIPPING_PLACE") && (
          <TableBuilderColumn header="받는이 출고지">
            {(order: Order) =>
              shippingPlaces.find(
                (sp) => sp.id === order.receiver.shippingPlaceId
              )?.name ?? ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("CLIENT_ORDER_ID") && (
          <TableBuilderColumn header="주문번호">
            {(order: Order) => order.clientOrderId}
          </TableBuilderColumn>
        )}
        {columnNames.includes("PRODUCT_NAME") && (
          <TableBuilderColumn
            header="물품명"
            id={SORT_KEYS.productName}
            sortable
          >
            {(order: Order) => {
              const showsProductId =
                clientId === "195720472966" || clientId === "549217590275"
              return order.products
                ?.map((product) =>
                  showsProductId
                    ? `${product.name} / ${product.clientProductId ?? ""}`
                    : product.name
                )
                .join(", ")
            }}
          </TableBuilderColumn>
        )}
        {columnNames.includes("TOTAL_PRODUCT_COUNT") && (
          <TableBuilderColumn header="물품 수량">
            {(order: Order) =>
              `${order.products
                .reduce((acc, { count }) => acc + count, 0)
                .toLocaleString()}개`
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("HNS_MALL_PRODUCT_CODE") && (
          <TableBuilderColumn
            header="상품코드"
            id={SORT_KEYS.hnsMallProductCode}
            sortable
          >
            {(order: Order) => order.note}
          </TableBuilderColumn>
        )}
        {columnNames.includes("SHIPPING_FEE") && (
          <TableBuilderColumn header="운송비">
            {(order: Order) =>
              `${(order.shippingFee?.totalAmount ?? 0).toLocaleString()}원`
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("CUSTOMER_NAME") && (
          <TableBuilderColumn header="고객 이름">
            {(order: Order) => getCustomer(order).name}
          </TableBuilderColumn>
        )}
        {columnNames.includes("CUSTOMER_PHONE") && (
          <TableBuilderColumn header="고객 전화번호">
            {(order: Order) => getCustomer(order).phone}
          </TableBuilderColumn>
        )}
        {columnNames.includes("CUSTOMER_POSTAL_CODE") && (
          <TableBuilderColumn header="고객 우편번호">
            {(order: Order) => getCustomer(order).address.postalCode}
          </TableBuilderColumn>
        )}
        {columnNames.includes("CUSTOMER_ADDRESS") && (
          <TableBuilderColumn
            header="고객 주소"
            id={SORT_KEYS.customerAddress}
            sortable
          >
            {(order: Order) => getFullAddress(getCustomer(order))}
          </TableBuilderColumn>
        )}
        {columnNames.includes("CUSTOMER_PREFERENCE") && (
          <TableBuilderColumn header="고객 배송 요청사항">
            {(order: Order) => getCustomer(order).preference}
          </TableBuilderColumn>
        )}
        {columnNames.includes("ORDER_TIME") && (
          <TableBuilderColumn header="접수 시각">
            {(order: Order) =>
              dayjs(order.orderTime).format("YYYY-MM-DD HH:mm:ss")
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("TAKE_OUT_TIME") && (
          <TableBuilderColumn
            header={client?.role === "SME" ? "픽업 요청시각" : "출고 시각"}
          >
            {(order: Order) =>
              order.takeOutTime
                ? dayjs(order.takeOutTime).format("YYYY-MM-DD HH:mm:ss")
                : ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("PICK_UP_TIME") && (
          <TableBuilderColumn header="인수 시각">
            {(order: Order) =>
              order.pickUpTime
                ? dayjs(order.pickUpTime).format("YYYY-MM-DD HH:mm:ss")
                : ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("DELIVERY_TIME") && (
          <TableBuilderColumn header="배송완료 시각">
            {(order: Order) =>
              order.state === "DELIVERED" && order.resolveTime
                ? dayjs(order.resolveTime).format("YYYY-MM-DD HH:mm:ss")
                : ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("QUIT_TIME") && (
          <TableBuilderColumn header="배송중단 시각">
            {(order: Order) =>
              order.state === "QUIT" && order.resolveTime
                ? dayjs(order.resolveTime).format("YYYY-MM-DD HH:mm:ss")
                : ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("HOLD_DELIVERY_TIME") && (
          <TableBuilderColumn header="배송보류 시각">
            {(order: Order) =>
              getHoldTimeOfHoldingDeliveryOrder(order, heldLoadsById) ?? ""
            }
          </TableBuilderColumn>
        )}
        {columnNames.includes("STATION_CODE") && (
          <TableBuilderColumn
            header="정류소"
            id={SORT_KEYS.stationCode}
            sortable
            overrides={{
              TableBodyCell: {
                style: {
                  textAlign: "center",
                },
              },
            }}
          >
            {(order: Order) => {
              if (!regionSets)
                return <FaSpinner className="inline-block animate-spin" />
              return getStationCode(order, regionSets)
            }}
          </TableBuilderColumn>
        )}
      </TableBuilder>
    </div>
  )
}
