import dayjs from "dayjs"
import { atom } from "recoil"
import { v4 as uuid4 } from "uuid"

const key = "date"

export const dateRangeState = atom<Date[]>({
  key: `${key}/${uuid4()}`,
  default: [dayjs().subtract(7, "days").toDate(), new Date()],
})
