import axios from "axios"
import { TodayAPIClient } from "../common"

export class Invoice extends TodayAPIClient {
  constructor(baseUrl: string, token: string) {
    const axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    })

    axiosInstance.interceptors.response.use(
      (v) => v,
      (error) => {
        if (error.config && error.response?.status >= 500) {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(axiosInstance.request(error.config))
            }, 5000)
          })
        }
        return Promise.reject(error)
      }
    )
    super(axiosInstance)
  }

  async retrieveInvoiceImage({
    clientId,
    orderId,
    version,
    pageNumber,
    totalPageCount,
    rotate,
  }: {
    clientId: string
    orderId: string
    version: number
    pageNumber?: number
    totalPageCount?: number
    rotate?: boolean
  }): Promise<string> {
    const queryParams = new URLSearchParams()
    if (pageNumber) {
      queryParams.append("page_number", pageNumber.toString())
    }
    if (totalPageCount) {
      queryParams.append("total_page_count", totalPageCount.toString())
    }
    if (rotate) {
      queryParams.append("rotate", rotate.toString())
    }
    const response = await this.client.get(
      `/invoice/v${version}/clients/${clientId}/orders/${orderId}/invoice?${queryParams}`,
      {
        responseType: "arraybuffer",
      }
    )
    const image = Buffer.from(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      ),
      "binary"
    ).toString("base64")
    return `data:${response.headers[
      "content-type"
    ].toLowerCase()};base64,${image}`
  }

  async retrieveInvoicePdf(
    clientId: string,
    orderIds: string[]
  ): Promise<string> {
    const queryParams = new URLSearchParams({
      multipage: "true",
      order_id: orderIds.join(","),
    })
    const response = await this.client.get(
      `/invoice/v2/clients/${clientId}/orders/invoices?${queryParams}`,
      {
        responseType: "arraybuffer",
      }
    )
    const pdf = Buffer.from(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      ),
      "binary"
    ).toString("base64")
    return `data:${response.headers[
      "content-type"
    ].toLowerCase()};base64,${pdf}`
  }

  async retrieveInvoicePdfInternal(
    orderIds: string[],
    totalPageCount?: number,
    pageOffset?: number
  ): Promise<string> {
    const pdfByte = new Uint8Array(
      await this.retrieveInvoicePdfByteInternal(
        orderIds,
        totalPageCount,
        pageOffset
      )
    )
    const pdfBase64 = Buffer.from(
      pdfByte.reduce((data, byte) => data + String.fromCharCode(byte), ""),
      "binary"
    ).toString("base64")
    return `data:application/pdf;base64,${pdfBase64}`
  }

  async retrieveInvoicePdfByteInternal(
    orderIds: string[],
    totalPageCount?: number,
    pageOffset?: number,
    duplicateProductCount?: boolean
  ): Promise<ArrayBufferLike> {
    const queryParams = new URLSearchParams({
      multipage: "true",
      order_id: orderIds.join(","),
    })
    if (totalPageCount) {
      queryParams.append("total_page_count", `${totalPageCount}`)
    }
    if (pageOffset) {
      queryParams.append("page_offset", `${pageOffset}`)
    }
    if (duplicateProductCount) {
      queryParams.append("duplicate_product_count", "true")
    }
    const response = await this.client.get(
      `/invoice/v2/orders/invoices?${queryParams}`,
      {
        responseType: "arraybuffer",
      }
    )
    return response.data
  }
}
