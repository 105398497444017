import { Invoice } from "@today/api/invoice"
import { Taker } from "@today/api/taker"
import { Tracker } from "@today/api/tracker"
import { NextApiRequest } from "next"
import {
  INVOICE_BASE_URL,
  KEYCLOAK_JWT,
  TAKER_BASE_URL,
  TAKER_INTERNAL_BASE_URL,
  TRACKER_BASE_URL,
} from "../config"

export const SWR_AUTH_TOKEN_HEADER = "x-today-openapi-token"

function extractAuthToken(req: NextApiRequest) {
  return (req.headers[SWR_AUTH_TOKEN_HEADER] ?? "") as string
}

export const tracker = new Tracker(TRACKER_BASE_URL, KEYCLOAK_JWT)
export const takerInternal = new Taker(TAKER_INTERNAL_BASE_URL, KEYCLOAK_JWT)
export function taker(req: NextApiRequest) {
  return new Taker(TAKER_BASE_URL, extractAuthToken(req))
}

export function invoice(req: NextApiRequest) {
  return new Invoice(INVOICE_BASE_URL, extractAuthToken(req))
}
