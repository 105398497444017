import { Order } from "@today/api/taker"
import { RegionSet } from "@today/api/tracker"
import {
  getCustomer,
  getStationCode as getStationCodeFromRegionSetName,
} from "@today/lib"

const KOREA_REGION_ID = "ce6a2f48-370d-4330-9e2d-0be846173a00"

export function getStationCode(order: Order, regionSets?: RegionSet[]): string {
  const regionId = getCustomer(order).lastMileInfo?.regionId
  if (regionId === KOREA_REGION_ID) {
    return "X"
  }
  const regionSet = regionSets
    ?.filter((rs) => rs.regionSetPolicyType === "TODAY_EVENING")
    .find((rs) => rs.regionIds.includes(regionId))
  return getStationCodeFromRegionSetName(regionSet?.regionSetName)
}
