import { Client, Order } from "@today/api/taker"
import { Load } from "@today/api/tracker"

export function getOrderStateName(
  orderState: Order["state"],
  collectFromCustomer: boolean,
  loadState?: Load["state"],
  clientRole?: Client["role"]
) {
  switch (orderState) {
    case "TAKING_OUT":
      return collectFromCustomer ? "수거 정보 입력 대기 중" : "출고 확정 전"
    case "PENDING_COLLECT":
      return loadState === "HOLDING_COLLECT"
        ? "인수 보류"
        : clientRole === "SME"
        ? "픽업 요청됨"
        : collectFromCustomer
        ? "수거 대기 중"
        : "출고 확정됨"
    case "IN_SHIPPING":
      return loadState === "HOLDING_DELIVERY" ? "배송 보류" : "배송 중"
    case "DELIVERED":
      return "배송 완료"
    case "QUIT":
      return "배송 중단"
    case "CANCELED":
      return "취소됨"
    case "RETURNED":
      return "반품됨"
    default:
      return orderState
  }
}
