import { Client } from "@today/api/taker"
import { LegacyMergeWithReceiverAddress } from "./binder/legacy"

const legacyClients: { [s: string]: string } = {
  "147731683028": "100물류",
  "511393751380": "100물류",
  "110201815933": "CJ대한통운",
  "525887971183": "CJ대한통운",
  "133449864958": "FastBox",
  "585576362589": "FastBox",
  "165201184182": "NS홈쇼핑",
  "577867903037": "NS홈쇼핑",
  "195720472966": "W쇼핑",
  "549217590275": "W쇼핑",
  "144258372911": "슈닥",
  "588201124912": "슈닥",
  "195827224672": "꾸까",
  "567251159926": "꾸까",
  "118607006141": "글로썸",
  "563559060502": "글로썸",
  "155997922248": "도매도",
  "563616108567": "도매도",
  "182451073990": "청량몰",
  "593483519529": "청량몰",
  "131973694074": "홈앤쇼핑",
  "583025091234": "홈앤쇼핑",
  "156260282056": "락큰미트",
  "557835464723": "락큰미트",
  "162820667538": "에이투지",
  "559758330381": "에이투지",
  "165625040870": "오즈키즈",
  "566595762113": "오즈키즈",
  "170509961422": "달디과일",
  "552614532974": "달디과일",
  "138668683402": "제이미원더",
  "501642436992": "제이미원더",
  "175118667804": "도나티삭스",
  "548918684650": "도나티삭스",
  "192067354242": "제이지베이프",
  "581897613075": "제이지베이프",
  "106255207993": "위키드와이프",
  "576914127164": "위키드와이프",
  "170630736017": "위로상점",
  "530046420777": "위로상점",
  "152582936672": "우진 동대문",
  "516772561250": "우진 동대문",
  "156321155235": "허밍프룻",
  "544321152652": "허밍프룻",
  "104221212180": "LGL 스마트물류솔루션사업팀",
  "590988881160": "LGL 스마트물류솔루션사업팀",
  "108235707610": "어니스마켓",
  "510495161290": "어니스마켓",
  "137261548755": "무브먼트",
  "554023105983": "무브먼트",
  "181037754099": "강강술래푸드",
  "531746990080": "강강술래푸드",
  "155570418612": "우진",
  "544503821229": "우진",
  "121356650377": "크로스ENF",
  "546361394516": "크로스ENF",
  "130266357809": "GS홈쇼핑",
  "575127038693": "GS홈쇼핑",
  "124678982251": "로드맨 B2C",
  "597843884375": "로드맨 B2C",
  "140116971909": "로드맨 익일배송",
  "519525394416": "로드맨 익일배송",
  "102797167782": "로드맨 B2B",
  "586316304917": "로드맨 B2B",
}

export function isLegacyClient(client?: Client) {
  return client && !!legacyClients[client.clientId]
}
