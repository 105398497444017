import { useCallback } from "react"
import {
  Client,
  CreateOrderRequest,
  ListShippingPlacesResponse,
  Order,
  ShippingPlace,
} from "@today/api/taker"
import { useSWRWithAuth, useTakerAuth } from "@today/api"
import { useUserInfo } from "@today/auth"
import { ButtonGroup, MODE } from "baseui/button-group"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"
import { getShippingTypes, isLegacyClient } from "../../utils"
import _ from "lodash"
import { Select } from "baseui/select"
import { OrderInput } from "./OrderInput"
import { Button } from "baseui/button"
import { toaster } from "baseui/toast"
import axios from "axios"
import { getErrorMessage } from "@today/api/utils"
import { FaSpinner } from "react-icons/fa"
import { usePoints } from "../../providers/PointsProvider"
import { PointChargeNoticeModal } from "../points/PointChargeNoticeModal"

export function OrderCreatePage() {
  const router = useRouter()
  const { clientId, sellerName: defaultSellerName, clientRoles } = useUserInfo()
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { points, isUsingPoints } = usePoints()
  const taker = useTakerAuth()
  const returningInvoiceNumber = router.query.returning as string
  const duplicateInvoiceNumber = router.query.duplicate as string
  const isTruckCaller = clientRoles.includes("order:truck-caller")
  const [deprecatedShippingPlace, setDeprecatedShippingPlace] =
    useState<ShippingPlace>()
  const [isLoading, setLoading] = useState(false)
  const [senderShippingPlace, setSenderShippingPlace] =
    useState<ShippingPlace>()
  const [senderName, setSenderName] = useState("")
  const [senderPhone, setSenderPhone] = useState("")
  const [senderAddress, setSenderAddress] = useState("")
  const [senderAccessMethod, setSenderAccessMethod] = useState("")
  const [senderPreference, setSenderPreference] = useState("")
  const [receiverShippingPlace, setReceiverShippingPlace] =
    useState<ShippingPlace>()
  const [receiverName, setReceiverName] = useState("")
  const [receiverPhone, setReceiverPhone] = useState("")
  const [receiverAddress, setReceiverAddress] = useState("")
  const [receiverAccessMethod, setReceiverAccessMethod] = useState("")
  const [receiverPreference, setReceiverPreference] = useState("")
  const [clientOrderId, setClientOrderId] = useState("")
  const [clientShippingId, setClientShippingId] = useState("")
  const [note, setNote] = useState("")
  const [productName, setProductName] = useState("")
  const [productCount, setProductCount] = useState("1")
  const [productPrice, setProductPrice] = useState("0")
  const [fragile, setFragile] = useState(false)
  const [fresh, setFresh] = useState(false)
  const [boxSize, setBoxSize] = useState<"소" | "중" | "대">("소")
  const [productCategory, setProductCategory] = useState("")
  const [sellerName, setSellerName] = useState(defaultSellerName ?? "")
  const [clientProductId, setClientProductId] = useState("")
  const [isTest, setTest] = useState(false)

  const [showPointsInfoModal, setShowPointsInfoModal] = useState(false)
  const [shippingFee, setShippingFee] = useState(0)

  // shipping type별 UI 렌더링 변화
  const shippingTypes = useMemo(() => getShippingTypes(client), [client])
  const isLegacy = isLegacyClient(client)
  const isSME = client?.role === "SME"
  let showSenderShippingPlaceInput =
    !isLegacy &&
    (shippingTypes.includes("STATION_TO_STATION") ||
      shippingTypes.includes("STATION_TO_LM"))
  let showReceiverShippingPlaceInput =
    !isLegacy &&
    !isSME &&
    (shippingTypes.includes("STATION_TO_STATION") ||
      shippingTypes.includes("LM_TO_STATION"))
  let shouldSenderShippingPlaceExist =
    showSenderShippingPlaceInput &&
    !shippingTypes.includes("LM_TO_STATION") &&
    !shippingTypes.includes("LM_TO_LM")
  let shouldReceiverShippingPlaceExist =
    showReceiverShippingPlaceInput &&
    !shippingTypes.includes("STATION_TO_LM") &&
    !shippingTypes.includes("LM_TO_LM")
  if (returningInvoiceNumber) {
    ;[shouldSenderShippingPlaceExist, shouldReceiverShippingPlaceExist] = [
      shouldReceiverShippingPlaceExist,
      shouldSenderShippingPlaceExist,
    ]
    ;[showSenderShippingPlaceInput, showReceiverShippingPlaceInput] = [
      showReceiverShippingPlaceInput,
      showSenderShippingPlaceInput,
    ]
  }

  const isButtonEnabled =
    (isLegacy
      ? deprecatedShippingPlace
      : (!shouldSenderShippingPlaceExist || senderShippingPlace) &&
        (!shouldReceiverShippingPlaceExist || receiverShippingPlace)) &&
    senderName &&
    senderPhone &&
    senderAddress &&
    receiverName &&
    receiverPhone &&
    receiverAddress &&
    productName
  const { data: shippingPlacesData } =
    useSWRWithAuth<ListShippingPlacesResponse>(
      clientId && `/api/clients/${clientId}/shipping-places`
    )
  const shippingPlaces = useMemo(
    () =>
      shippingPlacesData?.shippingPlaces.sort((sp1, sp2) =>
        sp1.name.localeCompare(sp2.name)
      ) ?? [],
    [shippingPlacesData]
  )
  useEffect(() => {
    if (shippingPlaces.length !== 1) return
    if (
      shippingTypes.length === 1 &&
      shippingTypes[0] === "STATION_TO_LM" &&
      !deprecatedShippingPlace
    ) {
      setDeprecatedShippingPlace(shippingPlaces[0])
    }
    if (
      !(
        shippingTypes.includes("LM_TO_LM") ||
        shippingTypes.includes("LM_TO_STATION")
      ) &&
      !senderShippingPlace
    ) {
      setSenderShippingPlace(shippingPlaces[0])
    }
    if (
      !(
        shippingTypes.includes("LM_TO_LM") ||
        shippingTypes.includes("STATION_TO_LM")
      ) &&
      !receiverShippingPlace
    ) {
      setReceiverShippingPlace(shippingPlaces[0])
    }
  }, [
    deprecatedShippingPlace,
    senderShippingPlace,
    receiverShippingPlace,
    isLegacy,
    shippingPlaces,
    shippingTypes,
  ])

  const [pickUpTruckCounts, setPickUpTruckCounts] = useState<{
    [k in TruckType]?: number | undefined
  }>({
    DAMAS: 0,
    LABO: 0,
    "1_TON": 0,
  })
  const pickUpTrucks = useMemo(() => {
    return Object.entries(pickUpTruckCounts)
      .map(([type, count]) => ({
        type,
        count,
      }))
      .filter(({ count }) => count && count > 0)
  }, [pickUpTruckCounts])

  const [estimatedShippingFee, setEstimatedShippingFee] = useState(0)
  const [estimationErrorMessage, setEstimationErrorMessage] = useState<string>()
  const [isFetchingEstimatedShippingFee, setFetchingEstimatedShippingFee] =
    useState(false)
  const debouncedFetchEstimatedShippingFee = useCallback(
    _.debounce(async () => {
      if (
        !client ||
        !senderAddress ||
        !receiverAddress ||
        pickUpTrucks.length === 0
      ) {
        setEstimatedShippingFee(0)
        setEstimationErrorMessage(undefined)
        setFetchingEstimatedShippingFee(false)
        return
      }
      const deliveryClass = client.conditions
        .find((condition) =>
          condition.deliveryClasses.some((dc) => dc.startsWith("TOGETHER_"))
        )
        ?.deliveryClasses.find((dc) => dc.startsWith("TOGETHER_"))
      if (!deliveryClass) {
        setEstimatedShippingFee(0)
        setEstimationErrorMessage(undefined)
        setFetchingEstimatedShippingFee(false)
        return
      }
      try {
        const { shippingFees } = await taker.bulkExpectShippingFees(
          client.clientId,
          deliveryClass,
          [
            {
              senderAddress,
              receiverAddress,
              pickUpTrucks,
            },
          ]
        )
        setEstimatedShippingFee(shippingFees[0])
        setEstimationErrorMessage(undefined)
      } catch (err) {
        setEstimatedShippingFee(0)
        setEstimationErrorMessage(getErrorMessage(err))
      }
      setFetchingEstimatedShippingFee(false)
    }, 1000),
    [pickUpTrucks, taker, client, senderAddress, receiverAddress]
  )
  useEffect(() => {
    ;(async () => {
      setFetchingEstimatedShippingFee(true)
      await debouncedFetchEstimatedShippingFee()
    })()
    return () => debouncedFetchEstimatedShippingFee.cancel()
  }, [debouncedFetchEstimatedShippingFee])

  const { data: returningOrder } = useSWRWithAuth<Order>(
    clientId &&
      returningInvoiceNumber &&
      `/api/clients/${clientId}/orders/${returningInvoiceNumber}?type=invoice_number`
  )
  const { data: duplicateOrder } = useSWRWithAuth<Order>(
    clientId &&
      duplicateInvoiceNumber &&
      `/api/clients/${clientId}/orders/${duplicateInvoiceNumber}?type=invoice_number`
  )
  useEffect(() => {
    if (returningOrder) {
      const { sender, receiver, products } = returningOrder
      const product = products[0]
      setSenderShippingPlace(
        shippingPlaces.find((sp) => sp.id === receiver.shippingPlaceId)
      )
      setSenderName(receiver.name)
      setSenderPhone(receiver.phone)
      setSenderAddress(receiver.rawAddress)
      setSenderAccessMethod(receiver.accessMethod)
      setSenderPreference(receiver.preference)
      setReceiverShippingPlace(
        shippingPlaces.find((sp) => sp.id === sender.shippingPlaceId)
      )
      setReceiverName(sender.name)
      setReceiverPhone(sender.phone)
      setReceiverAddress(sender.rawAddress)
      setClientOrderId(returningOrder.clientOrderId)
      setNote(returningOrder.note)
      setProductName(product.name)
      setProductPrice(product.price.toString())
      setFragile(product.fragile ?? false)
      setProductCategory(product.category ?? "")
      setSellerName(product.sellerName ?? "")
      setClientProductId(product.clientProductId ?? "")
    }
  }, [returningOrder, shippingPlaces])
  useEffect(() => {
    if (duplicateOrder) {
      const { sender, receiver, products } = duplicateOrder
      const product = products[0]
      setSenderShippingPlace(
        shippingPlaces.find((sp) => sp.id === sender.shippingPlaceId)
      )
      setSenderName(sender.name)
      setSenderPhone(sender.phone)
      setSenderAddress(sender.rawAddress)
      setSenderAccessMethod(sender.accessMethod)
      setSenderPreference(sender.preference)
      setReceiverShippingPlace(
        shippingPlaces.find((sp) => sp.id === receiver.shippingPlaceId)
      )
      setReceiverName(receiver.name)
      setReceiverPhone(receiver.phone)
      setReceiverAddress(receiver.rawAddress)
      setReceiverAccessMethod(receiver.accessMethod)
      setReceiverPreference(receiver.preference)
      setClientOrderId(duplicateOrder.clientOrderId)
      setNote(duplicateOrder.note)
      setProductName(product.name)
      setProductPrice(product.price.toString())
      setFragile(product.fragile ?? false)
      setProductCategory(product.category ?? "")
      setSellerName(product.sellerName ?? "")
      setClientProductId(product.clientProductId ?? "")
    }
  }, [duplicateOrder, shippingPlaces])
  useEffect(() => {
    if (senderShippingPlace) {
      setSenderAccessMethod("")
      setSenderPreference("")
    }
  }, [senderShippingPlace])
  useEffect(() => {
    if (receiverShippingPlace) {
      setReceiverAccessMethod("")
      setReceiverPreference("")
    }
  }, [receiverShippingPlace])
  return (
    <div className="h-full w-full overflow-y-scroll px-4">
      <div className="mx-auto mb-16 mt-8 flex max-w-6xl flex-col gap-y-8 md:mt-12">
        <div className="text-3xl font-bold md:mb-4">
          {returningInvoiceNumber
            ? "반품 배송 요청"
            : duplicateInvoiceNumber
            ? "추가 송장 발행"
            : "배송 요청"}
        </div>
        {isLegacy && (
          <div className="flex flex-col gap-4">
            <div className="text-xl font-semibold">
              출고지 <span className="text-red-500">*</span>
            </div>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
              <div className="col-span-2">
                <Select
                  value={deprecatedShippingPlace && [deprecatedShippingPlace]}
                  onChange={({ value }) =>
                    setDeprecatedShippingPlace(value[0] as ShippingPlace)
                  }
                  options={shippingPlaces}
                  valueKey="id"
                  labelKey="name"
                  placeholder="선택..."
                />
              </div>
              <div className="flex-[3]" />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-y-4">
          <div className="text-xl font-semibold">보내는이</div>
          <div className="mt-4 grid grid-cols-2 gap-4 md:grid-cols-4">
            {showSenderShippingPlaceInput && (
              <>
                <div className="col-span-2">
                  <OrderInput
                    label="출고지"
                    required={shouldSenderShippingPlaceExist}
                    component={
                      <Select
                        value={senderShippingPlace && [senderShippingPlace]}
                        onChange={({ value }) =>
                          setSenderShippingPlace(value[0] as ShippingPlace)
                        }
                        options={shippingPlaces}
                        valueKey="id"
                        labelKey="name"
                        placeholder="선택..."
                      />
                    }
                  />
                </div>
                <div className="col-span-2 hidden md:block" />
              </>
            )}
            <div className="col-span-1">
              <OrderInput
                label="이름"
                required
                value={senderName}
                onValueChange={setSenderName}
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="전화번호"
                required
                value={senderPhone}
                onValueChange={setSenderPhone}
              />
            </div>
            <div className="col-span-2">
              <OrderInput
                label="주소"
                required
                value={senderAddress}
                onValueChange={setSenderAddress}
              />
            </div>
            {((isLegacy && returningInvoiceNumber) || !senderShippingPlace) && (
              <>
                <div className="col-span-2">
                  <OrderInput
                    label="배송지 출입 방법"
                    value={senderAccessMethod}
                    onValueChange={setSenderAccessMethod}
                    maxLength={100}
                    placeholder="예) 공동현관 #1234#"
                  />
                </div>
                <div className="col-span-2">
                  <OrderInput
                    label="고객 요청사항"
                    value={senderPreference}
                    onValueChange={setSenderPreference}
                    maxLength={100}
                    placeholder="예) 문 앞에 놓아주세요."
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-xl font-semibold">받는이</div>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {showReceiverShippingPlaceInput && (
              <>
                <div className="col-span-2">
                  <OrderInput
                    label="출고지"
                    required={shouldReceiverShippingPlaceExist}
                    component={
                      <Select
                        value={receiverShippingPlace && [receiverShippingPlace]}
                        onChange={({ value }) =>
                          setReceiverShippingPlace(value[0] as ShippingPlace)
                        }
                        options={shippingPlaces}
                        valueKey="id"
                        labelKey="name"
                        placeholder="선택..."
                      />
                    }
                  />
                </div>
                <div className="col-span-2 hidden md:block" />
              </>
            )}
            <div className="col-span-1">
              <OrderInput
                label="이름"
                required
                value={receiverName}
                onValueChange={setReceiverName}
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="전화번호"
                required
                value={receiverPhone}
                onValueChange={setReceiverPhone}
              />
            </div>
            <div className="col-span-2">
              <OrderInput
                label="주소"
                required
                value={receiverAddress}
                onValueChange={setReceiverAddress}
              />
            </div>
            {((isLegacy && !returningInvoiceNumber) ||
              !receiverShippingPlace) && (
              <>
                <div className="col-span-2">
                  <OrderInput
                    label="배송지 출입 방법"
                    value={receiverAccessMethod}
                    onValueChange={setReceiverAccessMethod}
                    maxLength={100}
                    placeholder="예) 공동현관 #1234#"
                  />
                </div>
                <div className="col-span-2">
                  <OrderInput
                    label="고객 요청사항"
                    value={receiverPreference}
                    onValueChange={setReceiverPreference}
                    maxLength={100}
                    placeholder="예) 문 앞에 놓아주세요."
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-xl font-semibold">주문 정보</div>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <div className="col-span-1">
              <OrderInput
                label="내부 주문번호"
                value={clientOrderId}
                onValueChange={setClientOrderId}
                placeholder="고객사 내부 주문번호"
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="내부 배송 ID"
                value={clientShippingId}
                onValueChange={setClientShippingId}
                placeholder="고객사 내부 배송 ID"
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="비고"
                value={note}
                onValueChange={setNote}
                maxLength={100}
                placeholder="고객사 기록용"
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <div className="mb-2 text-sm">테스트 주문인가요?</div>
              <ButtonGroup
                mode={MODE.radio}
                selected={isTest ? 0 : 1}
                onClick={(_event, index) => setTest(index === 0)}
              >
                <Button>예</Button>
                <Button>아니오</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-xl font-semibold">물품 정보</div>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <div className="col-span-1">
              <OrderInput
                label="물품 이름"
                required
                value={productName}
                onValueChange={setProductName}
                maxLength={100}
                placeholder="예) iPhone 13 Pro"
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="물품 개수"
                required
                value={productCount}
                onChange={(event) => {
                  setProductCount(event.currentTarget.value)
                }}
                onBlur={() => {
                  if (isNaN(parseInt(productCount))) {
                    setProductCount("1")
                  }
                }}
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="물품 가격"
                required
                value={productPrice}
                onChange={(event) => {
                  setProductPrice(event.currentTarget.value)
                }}
                onBlur={() => {
                  if (isNaN(parseInt(productPrice))) {
                    setProductPrice("0")
                  }
                }}
                trailing="원"
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <div className="mb-2 text-sm">깨지기 쉬운 상품인가요?</div>
              <ButtonGroup
                mode={MODE.radio}
                selected={fragile ? 0 : 1}
                onClick={(_event, index) => setFragile(index === 0)}
              >
                <Button>예</Button>
                <Button>아니오</Button>
              </ButtonGroup>
            </div>
            <div className="col-span-2">
              <OrderInput
                label="상품 ID"
                value={clientProductId}
                onValueChange={setClientProductId}
                placeholder="고객사 내부에서 관리하는 상품 ID"
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="물품 카테고리"
                value={productCategory}
                onValueChange={setProductCategory}
              />
            </div>
            <div className="col-span-1">
              <OrderInput
                label="판매자명"
                value={sellerName}
                onValueChange={setSellerName}
              />
            </div>
            <div className="col-span-3 flex flex-col">
              <div className="mb-2 text-sm">물품 체적 정보</div>
              <ButtonGroup
                mode={MODE.radio}
                selected={["소", "중", "대"].indexOf(boxSize)}
                onClick={(_event, index) => {
                  const arr: (typeof boxSize)[] = ["소", "중", "대"]
                  setBoxSize(arr[index])
                }}
              >
                <Button>소 (100cm/5kg 이하)</Button>
                <Button>중 (120cm/10kg 이하)</Button>
                <Button>대 (140cm/15kg 이하)</Button>
              </ButtonGroup>
            </div>
            <div className="col-span-1 flex flex-col">
              <div className="mb-2 text-sm">아이스박스인가요?</div>
              <ButtonGroup
                mode={MODE.radio}
                selected={fresh ? 0 : 1}
                onClick={(_event, index) => setFresh(index === 0)}
              >
                <Button>예</Button>
                <Button>아니오</Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
        {isTruckCaller && (
          <div className="flex flex-col gap-4">
            <div className="text-xl font-semibold">호출 옵션</div>
            <div className="grid grid-cols-4 gap-4">
              {truckTypes.map(({ key, name }) => (
                <div className="col-span-1">
                  <OrderInput
                    key={key}
                    label={name}
                    required
                    value={pickUpTruckCounts[key]}
                    onValueChange={(v) =>
                      setPickUpTruckCounts((truckCounts) => ({
                        ...truckCounts,
                        [key]: v.trim() === "" ? undefined : Number.parseInt(v),
                      }))
                    }
                    type="numeric"
                  />
                </div>
              ))}
              <div className="col-span-1 flex flex-col">
                <div className="mb-2 text-sm">예상 운송비</div>
                <div className="flex flex-grow flex-row items-center text-xl">
                  {isFetchingEstimatedShippingFee ? (
                    <FaSpinner className="mb-[2px] inline-block animate-spin" />
                  ) : estimationErrorMessage ? (
                    <span className="text-sm text-red-500">
                      {estimationErrorMessage}
                    </span>
                  ) : (
                    <>{estimatedShippingFee.toLocaleString()}원</>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <Button
            isLoading={isLoading}
            disabled={!isButtonEnabled}
            onClick={async () => {
              if (!clientId) {
                return
              }
              if (shouldSenderShippingPlaceExist && !senderShippingPlace) {
                toaster.negative(<p>보내는이 출고지를 설정해야 합니다.</p>)
                return
              }
              // if (shouldReceiverShippingPlaceExist && !receiverShippingPlace) {
              //   toaster.negative(<p>받는이 출고지를 설정해야 합니다.</p>)
              //   return
              // }
              if (
                !isLegacy &&
                shippingTypes.includes("STATION_TO_STATION") &&
                senderShippingPlace &&
                receiverShippingPlace &&
                senderShippingPlace.id === receiverShippingPlace.id
              ) {
                toaster.negative(<p>두 출고지가 같을 수 없습니다.</p>)
                return
              }
              if (
                !isLegacy &&
                !shippingTypes.includes("STATION_TO_STATION") &&
                senderShippingPlace &&
                receiverShippingPlace
              ) {
                toaster.negative(
                  <p>출고지 두 개를 동시에 설정할 수 없습니다.</p>
                )
                return
              }
              // if (
              //   !isLegacy &&
              //   !shippingTypes.includes("LM_TO_LM") &&
              //   !senderShippingPlace &&
              //   !receiverShippingPlace
              // ) {
              //   toaster.negative(<p>출고지를 설정해야 합니다.</p>)
              //   return
              // }
              if (
                isTruckCaller &&
                pickUpTrucks.reduce((acc, { count }) => acc + count, 0) === 0
              ) {
                toaster.negative(<p>호출 옵션을 설정해주십시오.</p>)
                return
              }
              if (isUsingPoints && client) {
                setLoading(true)
                const incheon24 = client.conditions.some((c) =>
                  c.deliveryClasses.includes("INCHEON_24")
                )
                const { shippingFees } = await taker.bulkExpectShippingFees(
                  clientId,
                  incheon24 ? "INCHEON_24" : "INCHEON_48",
                  [
                    {
                      fresh,
                      size: boxSize,
                    },
                  ]
                )
                setLoading(false)
                const fee = shippingFees.reduce((acc, n) => acc + n, 0)
                if (fee > points.remainingPoints) {
                  // TODO 선결제
                  //setShippingFee(fee)
                  //setShowPointsInfoModal(true)
                  //return
                }
                if (
                  !confirm(
                    // TODO 선결제
                    //`${fee.toLocaleString()}원이 포인트에서 차감됩니다. 주문을 진행할까요?`
                    `포인트가 차감됩니다. 주문을 진행할까요?`
                  )
                ) {
                  return
                }
              }
              setLoading(true)
              const request: CreateOrderRequest = {
                sender: {
                  shippingPlaceId: isLegacy
                    ? returningInvoiceNumber
                      ? undefined
                      : deprecatedShippingPlace!.id
                    : senderShippingPlace?.id,
                  name: senderName,
                  phone: senderPhone,
                  address: senderAddress,
                  accessMethod: senderAccessMethod,
                  preference: senderPreference,
                },
                receiver: {
                  shippingPlaceId: isLegacy
                    ? returningInvoiceNumber
                      ? deprecatedShippingPlace!.id
                      : undefined
                    : receiverShippingPlace?.id,
                  name: receiverName,
                  phone: receiverPhone,
                  address: receiverAddress,
                  accessMethod: receiverAccessMethod,
                  preference: receiverPreference,
                },
                clientOrderId,
                clientShippingId,
                physicalAttributes: {
                  size: boxSize,
                  fresh,
                  fragile,
                },
                note,
                products: [
                  {
                    name: productName,
                    price: parseInt(productPrice),
                    fragile,
                    sellerName,
                    clientProductId,
                    count: parseInt(productCount),
                  },
                ],
                returningInfo: returningInvoiceNumber
                  ? { invoiceNumber: returningInvoiceNumber }
                  : null,
                developerPayload:
                  isTest || (isTruckCaller && pickUpTrucks.length > 0)
                    ? JSON.stringify({
                        "@today": {
                          ...(isTest ? { test: true } : {}),
                          ...(isTruckCaller && pickUpTrucks.length > 0
                            ? {
                                shipping_fee: {
                                  pick_up_trucks: pickUpTrucks,
                                },
                              }
                            : {}),
                        },
                      })
                    : "",
              }
              try {
                await taker.createOrder(clientId, request)
                toaster.info(<>배송이 등록되었습니다.</>, {})
                router.push("/orders")
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  toaster.negative(
                    <span>
                      {JSON.stringify(error.response?.data.errorMessage)}
                    </span>,
                    {}
                  )
                }
              } finally {
                setLoading(false)
              }
            }}
          >
            {returningInvoiceNumber ? "반품" : "주문"}
          </Button>
        </div>
      </div>
      <PointChargeNoticeModal
        open={showPointsInfoModal}
        fee={shippingFee}
        onClose={() => {
          setShowPointsInfoModal(false)
          setShippingFee(0)
        }}
      />
    </div>
  )
}

type TruckType = "DAMAS" | "LABO" | "1_TON"

const truckTypes: { key: TruckType; name: string }[] = [
  {
    key: "DAMAS",
    name: "다마스",
  },
  {
    key: "LABO",
    name: "라보",
  },
  {
    key: "1_TON",
    name: "1톤트럭",
  },
]
