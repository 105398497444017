import { Driver, RegionSet, Route } from "@today/api/tracker"

export function getRegionSetsOfDrivers(
  drivers: Driver[],
  regionSets: RegionSet[],
  routes: Route[]
) {
  const assignedRoutes = routes.filter((route) =>
    drivers.map((driver) => driver.assignedRouteId).includes(route.id)
  )
  return regionSets.filter((regionSet) =>
    assignedRoutes
      .map((route) => route.regionSetName)
      .includes(regionSet.regionSetName)
  )
}
