import { ShippingPlace } from "@today/api/taker"
import { ALIGN, Radio, RadioGroup } from "baseui/radio"
import { useState } from "react"

type ShippingPlaceFilterOption = "ALL" | ShippingPlace["id"]

type ShippingPlaceFilterProps = {
  shippingPlaces: ShippingPlace[]
  option: ShippingPlaceFilterOption
  setOption: (opt: ShippingPlaceFilterOption) => void
}

export function useShippingPlaceFilter() {
  const [option, setOption] = useState<ShippingPlaceFilterOption>("ALL")
  return { option, setOption }
}

export function ShippingPlaceFilter({
  shippingPlaces,
  option,
  setOption,
}: ShippingPlaceFilterProps) {
  return (
    <>
      <div className="ml-2 font-bold">출고지 필터</div>
      <div className="ml-2">
        <RadioGroup
          value={option}
          onChange={(e) =>
            setOption(e.currentTarget.value as ShippingPlaceFilterOption)
          }
          name="shipping-place"
          align={ALIGN.horizontal}
        >
          <Radio value="ALL">전체</Radio>
          {shippingPlaces.map((shippingPlace) => (
            <Radio key={shippingPlace.id} value={shippingPlace.id}>
              {shippingPlace.name}
            </Radio>
          ))}
        </RadioGroup>
      </div>
    </>
  )
}
