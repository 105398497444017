import { Button, ButtonProps } from "baseui/button"
import { useState } from "react"

type AsyncActionButtonProps = ButtonProps & {
  onClick(): Promise<void>
}

export function AsyncActionButton({
  onClick,
  ...props
}: AsyncActionButtonProps) {
  const [isLoading, setLoading] = useState(false)
  return (
    <Button
      {...props}
      isLoading={isLoading}
      onClick={() => {
        setLoading(true)
        onClick().finally(() => setLoading(false))
      }}
    />
  )
}
