import { AtomEffect, atom } from "recoil"
import { v4 as uuid4 } from "uuid"

const key = "delayNoticeConfirmTime"

export const delayNoticeConfirmTimeState = atom<Date | null>({
  key: `${key}/${uuid4()}`,
  default: null,
  effects: [localStorageEffect(key)],
})

// TODO: 급히 배포해야 하는데 localForage 사용 시 crash 하는 문제가 있어서 코드 중복을 감수함.
function localStorageEffect(key: string): AtomEffect<Date | null> {
  return ({ setSelf, onSet }) => {
    if (typeof localStorage === "undefined") return
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(new Date(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset || !newValue
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, newValue.toISOString())
    })
  }
}
