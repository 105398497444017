export const MY_BASE_URL = process.env.NEXT_PUBLIC_MY_BASE_URL as string
export const TAKER_BASE_URL = process.env.NEXT_PUBLIC_TAKER_BASE_URL as string
export const TRACKER_BASE_URL = process.env
  .NEXT_PUBLIC_TRACKER_BASE_URL as string
export const INVOICE_BASE_URL = process.env
  .NEXT_PUBLIC_INVOICE_BASE_URL as string
export const KEYCLOAK_JWT = process.env.KEYCLOAK_JWT as string
export const authConfig = {
  url: process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL as string,
  realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string,
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
}
export const ENV = process.env.NEXT_PUBLIC_ENV as string

export const TAKER_INTERNAL_BASE_URL = process.env
  .TAKER_INTERNAL_BASE_URL as string

export const TOAST_BASE_URL = process.env.TOAST_BASE_URL as string
export const TOAST_APP_KEY = process.env.TOAST_APP_KEY as string
export const TOAST_SENDER_KEY = process.env.TOAST_SENDER_KEY as string
export const TOAST_SECRET_KEY = process.env.TOAST_SECRET_KEY as string

export const KEYCLOAK_ADMIN_CLIENT_ID = process.env
  .KEYCLOAK_ADMIN_CLIENT_ID as string
export const KEYCLOAK_ADMIN_SECRET_KEY = process.env
  .KEYCLOAK_ADMIN_SECRET_KEY as string

export const KEYCLOAK_ADMIN_AZP = process.env.KEYCLOAK_ADMIN_AZP as string

export const SLACK_TOKEN = process.env.SLACK_TOKEN as string

export const NAVER_WORKS_URL = process.env.NAVER_WORKS_URL as string
export const NAVER_WORKS_BOT_ID = process.env.NAVER_WORKS_BOT_ID as string
export const NAVER_WORKS_CLIENT_ID = process.env.NAVER_WORKS_CLIENT_ID as string
export const NAVER_WORKS_CLIENT_SECRET = process.env
  .NAVER_WORKS_CLIENT_SECRET as string
export const NAVER_WORKS_SERVICE_ACCOUNT = process.env
  .NAVER_WORKS_SERVICE_ACCOUNT as string
export const NAVER_WORKS_PRIVATE_KEY = process.env
  .NAVER_WORKS_PRIVATE_KEY as string

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string
