import { DeveloperPayload } from "@today/api/taker"

export function parseDeveloperPayload(payload?: string): DeveloperPayload {
  if (!payload) {
    return {}
  }
  try {
    return JSON.parse(payload)
  } catch {
    return {}
  }
}
