import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react"
import { Invoice } from "../invoice"
import { Taker } from "../taker"
import { Tracer } from "../tracer"
import { Tracker } from "../tracker"
import { MQTTAuth } from "../mqtt"
import applyCaseMiddleware from "axios-case-converter"
import axios, { Method } from "axios"
import { TodayAPIClient, TodayAPIRequestConfig } from "../common"
import { UrlRecordClient } from "../url-record"

// method 생략 && data 생략 - GET
// method 생략 && data 존재 - POST
type Fetcher = {
  <T = any>(
    url: string,
    method?: Method,
    data?: any,
    configs?: TodayAPIRequestConfig
  ): Promise<T>
  <T = any>(
    url: string,
    data?: Exclude<any, Method>,
    configs?: TodayAPIRequestConfig
  ): Promise<T>
}

export type APIConfig = {
  takerBaseUrl?: string
  trackerBaseUrl?: string
  tracerBaseUrl?: string
  invoiceBaseUrl?: string
  openApiBaseUrl?: string
}

const APIContext = createContext<APIConfig>({})

export const APIProvider = (props: PropsWithChildren<APIConfig>) => {
  return (
    <APIContext.Provider
      value={{
        takerBaseUrl: props.takerBaseUrl,
        trackerBaseUrl: props.trackerBaseUrl,
        tracerBaseUrl: props.tracerBaseUrl,
        invoiceBaseUrl: props.invoiceBaseUrl,
        openApiBaseUrl: props.openApiBaseUrl,
      }}
    >
      {props.children}
    </APIContext.Provider>
  )
}

export function useFetcher(client: TodayAPIClient): Fetcher
export function useFetcher(accessToken?: string): Fetcher

export function useFetcher(arg: TodayAPIClient | string | undefined) {
  return useMemo(() => {
    let client: TodayAPIClient
    if (typeof arg === "string" || arg === undefined) {
      const accessToken = arg
      client = new TodayAPIClient(
        applyCaseMiddleware(
          axios.create({
            headers: {
              ...(accessToken
                ? { Authorization: `Bearer ${accessToken}` }
                : {}),
            },
          })
        )
      )
    } else {
      client = arg
    }
    return client.fetcher()
  }, [arg])
}

export function useTaker(accessToken: string) {
  const { takerBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new Taker(takerBaseUrl ?? "", accessToken),
    [takerBaseUrl, accessToken]
  )
}

export function useTracker(accessToken: string) {
  const { trackerBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new Tracker(trackerBaseUrl ?? "", accessToken),
    [trackerBaseUrl, accessToken]
  )
}

export function useTracer(accessToken: string) {
  const { tracerBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new Tracer(tracerBaseUrl ?? "", accessToken),
    [tracerBaseUrl, accessToken]
  )
}

export function useInvoice(accessToken: string) {
  const { invoiceBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new Invoice(invoiceBaseUrl ?? "", accessToken),
    [invoiceBaseUrl, accessToken]
  )
}

export function useMqtt(accessToken: string) {
  const { trackerBaseUrl: mqttAuthBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new MQTTAuth(mqttAuthBaseUrl ?? "", accessToken),
    [mqttAuthBaseUrl]
  )
}

export function useUrlRecord(accessToken: string) {
  const { openApiBaseUrl } = useContext(APIContext)
  return useMemo(
    () => new UrlRecordClient(openApiBaseUrl ?? "", accessToken),
    [openApiBaseUrl, accessToken]
  )
}
