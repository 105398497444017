import { Order } from "@today/api/taker"
import { parseDeveloperPayload } from "./parseDeveloperPayload"

export function getForwardingInvoiceNumber(order?: Order): string | undefined {
  const forwardingInvoiceNumber = order?.forwardingInfo?.invoiceNumber
  if (forwardingInvoiceNumber) {
    return forwardingInvoiceNumber
  }
  // Kept for backward compatibility
  const developerPayload = parseDeveloperPayload(order?.developerPayload)
  return developerPayload["@today/cj"]?.invoice_number
}
