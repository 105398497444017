import { Input } from "baseui/input"
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal"
import { useState } from "react"

type CancelModalProps = {
  isOpen: boolean
  onClose: () => void
  onCancel: (reason: string) => Promise<void>
}

export function CancelModal({ isOpen, onClose, onCancel }: CancelModalProps) {
  const [cancelReason, setCancelReason] = useState("")
  const [isCanceling, setCanceling] = useState(false)
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Root: {
          style: () => ({
            zIndex: 50,
          }),
        },
      }}
    >
      <ModalHeader>배송요청 취소</ModalHeader>
      <ModalBody>
        <Input
          value={cancelReason}
          onChange={(event) => setCancelReason(event.currentTarget.value)}
          placeholder="취소 사유"
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          isLoading={isCanceling}
          onClick={async () => {
            setCanceling(true)
            await onCancel(cancelReason)
            setCancelReason("")
            setCanceling(false)
          }}
        >
          확인
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
