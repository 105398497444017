export function partition<T>(
  array: T[] | null | undefined,
  predicate: (element: T) => boolean
): [T[] | null, T[] | null] {
  if (!array) {
    return [null, null]
  }
  const yay: T[] = []
  const nay: T[] = []
  for (const element of array) {
    if (predicate(element)) {
      yay.push(element)
    } else {
      nay.push(element)
    }
  }
  return [yay, nay]
}
