import { OrgId, OrgIds, RegionSet } from "@today/api/tracker"

export function getRegionSetsOfOrg(orgId: OrgId, regionSets: RegionSet[]) {
  return regionSets.filter((rs) => {
    switch (orgId) {
      case OrgIds.kdlogistics:
        return (
          rs.regionSetName.startsWith("K") &&
          rs.regionSetPolicyType === "TODAY_EVENING"
        )

      default:
        throw new Error("Not Implemented")
    }
  })
}
