import { Column } from "./common"
import Default, { Row } from "./default"
import { Client, CreateOrderRequest, ShippingPlace } from "@today/api/taker"

export default class DBLKorea extends Default {
  readonly _columns: Column<Row>[] = [
    { label: "고객 주문번호", key: "clientOrderId" },
    { label: "고객 주문일시", key: "productCustomerRequestTime" },
    { label: "피킹 위치", key: "pickingLocation" },
    { label: "보낸이 출고지", key: "senderShippingPlaceName", optional: true },
    { label: "보낸이 이름", key: "senderName", optional: true },
    { label: "보낸이 전화번호", key: "senderPhone", optional: true },
    {
      label: ["보낸이 주소", "픽업지 주소"],
      key: "senderAddress",
      optional: true,
    },
    {
      label: "보낸이 건물 출입방법",
      key: "senderAccessMethod",
      optional: true,
    },
    { label: "보낸이 요청사항", key: "senderPreference", optional: true },
    {
      label: "받는이 출고지",
      key: "receiverShippingPlaceName",
      optional: true,
    },
    { label: "받는이 이름", key: "receiverName", optional: true },
    { label: "받는이 전화번호", key: "receiverPhone", optional: true },
    { label: "받는이 주소", key: "receiverAddress", optional: true },
    {
      label: "받는이 건물 출입방법",
      key: "receiverAccessMethod",
      optional: true,
    },
    { label: "받는이 요청사항", key: "receiverPreference", optional: true },
    { label: "셀러명", key: "sellerName", optional: true },
    { label: "판매채널", key: "channel" },
    { label: "상품명", key: "productName" },
    { label: "상품 수량", key: "productCount" },
    { label: "상품 카테고리", key: "productCategory" },
    { label: "상품 깨짐주의", key: "productFragile" },
    { label: "상품 가액", key: "productPrice" },
    { label: "상품 코드", key: "productCode" },
    { label: "반품 원송장번호", key: "originalInvoiceNumber" },
  ]

  get columns(): Column<Row>[] {
    return this._columns
  }

  overrideRow(row: Row, index: number) {
    const r = { ...row }
    if (r.senderName) {
      if (!r.receiverShippingPlaceName)
        row.receiverShippingPlaceName = "디비엘코리아-용인"
      if (!r.receiverName) row.receiverName = "디비엘코리아"
      if (!r.receiverPhone) row.receiverPhone = "031-336-1560"
      if (!r.receiverAddress)
        row.receiverAddress =
          "경기 용인시 처인구 백옥대로 967 (남동) 디비엘코리아"
    }
    if (r.receiverName) {
      if (!r.senderShippingPlaceName)
        row.senderShippingPlaceName = "디비엘코리아-용인"
      if (!r.senderName) row.senderName = "디비엘코리아"
      if (!r.senderPhone) row.senderPhone = "031-336-1560"
      if (!r.senderAddress)
        row.senderAddress =
          "경기 용인시 처인구 백옥대로 967 (남동) 디비엘코리아"
    }
    if (!r.sellerName) row.sellerName = "디비엘코리아"
  }

  createOrderRequests(
    client: Client,
    shippingPlace?: ShippingPlace,
    sellerName?: string
  ): { skipTakeOut: boolean; requests: CreateOrderRequest[] } {
    const req = super.createOrderRequests(client, shippingPlace, sellerName)
    if (!sellerName) return req
    return {
      ...req,
      requests: req.requests.map((r) => ({
        ...r,
        products: r.products.map((p) => ({
          ...p,
          sellerName: sellerName,
        })),
      })),
    }
  }
}
