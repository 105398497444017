export function formatRegistrationNumber(registrationNumber: string) {
  if (/[^\d-]/.test(registrationNumber)) {
    return undefined
  }
  const formattedRegistrationNumber = registrationNumber
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3")
  return /^\d{3}-\d{2}-\d{5}$/.test(formattedRegistrationNumber)
    ? formattedRegistrationNumber
    : undefined
}
